


.vacancies {
  width: 100%;
  height: auto;

  .breadcrumbs-block {
    @media screen and (max-width: $mediaTablet) {
      margin-left: 15px;
    }
  }

}

.vacancies-title {
  color: #000000;
  font-size: 36px;
  font-weight: $fontSemiBold;
  text-transform: uppercase;
  letter-spacing: 3.6px;
  margin: 45px 0 30px 0;

  @media screen and (max-width: $mediaTablet) {
    font-size: 24px;
    margin: 25px 0 25px 30px;
  }
}

.vacancies-advantages {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(124, 134, 156, 0.23);
  padding: 40px 30px 60px 30px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  position: relative;
  background-image: url(../images/601.jpg);

  @media screen and (max-width: $mediaTablet) {
    background-image: none;
    padding-bottom: 25px;
    padding-top: 15px;
  }

  h3 {
    letter-spacing: 1.8px;
    line-height: 1.5;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: $fontSemiBold;

    @media screen and (max-width: $mediaMobile) {
      font-size: 16px;
      letter-spacing: 1.4px;
    }
  }

  .vacancies-advantages-list {
    list-style-type: disc;
    margin-left: 15px;

    li {
      font-size: 14px;
      line-height: 1.5;
      color: #333;
      margin-bottom: 5px;
    }
  }

  .vacancies-advantages-manager {
    font-size: 16px;
    color: #000;
    font-weight: $fontSemiBold;
    margin: 30px 0px 15px 0px;
    display: block;

    @media screen and (max-width: $mediaMobile) {
      font-size: 14px;
    }
  }

  .vacancies-advantages-phone {
    display: flex;
    flex-direction: column;

    a {
      line-height: 1.5;
      font-weight: $fontSemiBold;
      color: #000;

      @media screen and (max-width: $mediaMobile) {
        color: #0296ff;
        line-height: 2.43;
        font-weight: $fontBold;
      }
    }
  }

}

.vacancies-item {
  box-shadow: 0 0 14px 0 rgba(124, 134, 156, 0.23);
  background-color: #fff;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .vacancies-item__preview {
    display: flex;
    flex-direction: column;
    padding-bottom: 38px;

    @media screen and (max-width: $mediaMobile) {
      padding-bottom: 32px
    }

    &-show-more {
      display: flex;
      align-items: center;
      color: #0296ff;
      font-size: 20px;
      font-weight: $fontSemiBold;
      cursor: pointer;
      transition: 0.3s;
      padding: 20px 30px;
      user-select: none;

      @media screen and (max-width: $mediaMobile) {
        font-size: 17px;
      }

      .show-more-arrow {
        width: 12px;
        height: 7px;
        fill: #0296ff;
        margin-left: 15px;
        transition: 0.3s;

        @media screen and (max-width: $mediaMobile) {
          width: 18px;
        }
      }

      .show-more-arrow-effect {
        transform: rotate(-180deg);
        fill: white !important;
      }

      &:hover {
        color: $colorRed;
      }

      &:hover .show-more-arrow {
        fill: $colorRed;
      }
    }

    &-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0px 30px 0px 30px;

      .vacancies-item__preview-list-item {
        display: flex;
        align-items: center;
        margin-right: 50px;

        @media screen and (max-width: $mediaTablet) {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media screen and (max-width: $mediaMobile) {
          margin-right: 15px;
        }

        .vacancies-item__preview-item-text {
          font-size: 16px;
          color: #333333;
          font-weight: $fontSemiBold;
        }

        .vacancies-wrapper-preview-svg1 {
          width: 21px;
          height: 19px;
          fill: #7c869c;
          margin-right: 15px;
        }

        .vacancies-wrapper-preview-svg2 {
          width: 23px;
          height: 20px;
          fill: #7c869c;
          margin-right: 15px;
        }

        .vacancies-wrapper-preview-svg3 {
          width: 15px;
          height: 22px;
          fill: #7c869c;
          margin-right: 15px;
        }
      }
    }

    &-information {
      display: block;
      padding: 18px 30px 20px 30px;
      color: #333333;
      font-size: 16px;
      line-height: 1.5;

      pre{
        white-space: pre-wrap;
      }

      @media screen and (max-width: $mediaMobile) {
        font-size: 14px;
        padding: 17px 15px 17px 30px;
      }

    }

    &-data {
      padding-left: 30px;
      display: block;
      color: #7c869c;
      font-weight: $fontSemiBold;
    }

    .show-more-effect {
      background-color: #3c3c49;
      transition: 0.3s;
      padding: 25px 30px;
      margin-bottom: 25px;
      color: #ffffff !important;
    }

    .vacancies-wrapper-preview-list-effect {
      order: 3;
      padding-top: 15px;
    }

    .vacancies-wrapper-preview-information-effect {
      height: 0;
      overflow: hidden;
      padding: 0 30px;
    }

  }

  .vacancies-wrapper-preview-effect {
    padding-bottom: 0;
  }


  .vacancies-item__more{
    margin-top: 35px;
    padding: 0px 30px 85px 30px;
    display: none;

    div {
      margin-bottom: 35px;

      pre{
        white-space: pre-wrap;
      }

      span {
        color: #000;
        font-weight: $fontSemiBold;
        font-size: 16px;
        margin-bottom: 30px;
        display: block;
      }

      ul{
        list-style-type: disc;
        margin-left: 20px;

        li {
          line-height: 1.5;
          color: #333;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }

    .vacancies-item__contacts {
      position: relative;
      z-index: 2;
      width: 100%;

      .vacancies-item__contacts-mobile-communication {
        font-size: 17px;
        color: #000000;
        font-weight: $fontSemiBold;
        text-align: center;
        display: block;
        letter-spacing: 0.5px;
        display: none;

        @media screen and (max-width: $mediaMobile) {
          display: block;
        }
      }

      &-communication {
        font-size: 20px;
        font-weight: $fontBold;
        color: #000;
        display: block;
        margin-bottom: 55px;

        @media screen and (max-width: $mediaMobile) {
          display: none;
        }
      }

      &-team {
        font-weight: $fontSemiBold;
        font-size: 36px;
        display: block;
        color: #333;

        @media screen and (max-width: $mediaTablet) {
          font-size: 24px;
        }

        @media screen and (max-width: $mediaMobile) {
          display: none;
        }
      }

      &-information {
        line-height: 1.5;
        color: #4a4a4a;
        width: 100%;
        max-width: 412px;
        margin: 30px 0px;
        font-size: 16px;

        @media screen and (max-width: $mediaMobile) {
          display: none;
        }
      }

      &-manager {
        line-height: 1.5;
        color: #4a4a4a;
        font-size: 16px;
        display: block;
        font-weight: $fontSemiBold;

        @media screen and (max-width: $mediaMobile) {
          display: none;
        }
      }

      &-phone {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 260px;
        height: 42px;
        background-color: #0296ff;
        margin-top: 35px;
        color: #fff;
        font-size: 18px;
        letter-spacing: 1px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: $colorRed;
        }

        @media screen and (max-width: $mediaMobile) {
          max-width: 480px;
        }
      }

      &-manager-img {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        margin-right: -30px;
        margin-bottom: -85px;

        @media screen and (max-width: $mediaTablet) {
          display: none;
        }
      }

    }

    @media screen and (max-width: $mediaMobile) {
      padding-bottom: 35px;
    }
  }

  .showContent {
    padding: 0px 30px 35px 30px;
  }



}

.crumbs-vacancies {
  margin: 15px 0px 35px 0px;
}

.vacancies-advantages-container {

  @media screen and (max-width: $mediaTablet) {
    padding: 0;
  }
}

.vacancies-wrapper-container {
  @media screen and (max-width: $mediaTablet) {
    padding: 0;
  }
}

// none vacancies
.vacancies-none {
  background-color: white;
  padding: 28px 22px 44px 22px;
  background-size: 350px 270px;
  background-position: right 72px bottom 0;
  background-repeat: no-repeat;

  @media (max-width:800px) {
    background-image: none !important;
  }

  .vacancies-none__wrapper{
    .vacancies-none__title{
      font-size: 24px;
      font-weight: 400;
      color: #de1f26;
      margin-bottom: 20px;

      @media (max-width:768px) {
        font-size: 18px;
      }
    }
    .vacancies-none__description{
      font-size: 14px;
      color: #000000;
      margin-bottom: 24px;
      max-width: 382px;
    }
    .vacancies-none__phone{
      display: flex;
      flex-direction: column;

      a{
        font-size: 18px;
        color: #333333;
      }
    }
  }
}








