$fontSemiBold: 600;
$fontBold: 700;


$colorBlack: #000;
$colorRed: #E52445;
$colorWhiteopacity: rgba(255,255,255,0.5);
$colorWhite: #fff;

$mediaMobile: 480px;
$mediaTablet: 820px;
$mediaNetbook: 1200px;

$mediaMiddle: 768px;


@media screen and (max-width: $mediaMobile){

}


