/*========== Шапка ==========*/

.header {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #000;
}

.header-img {
  //transition: all 0.3s;

  box-shadow: inset 0px 100px 192px -41px rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;
  padding-top: 25px;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: 0;
  left: 0;
}

.header-opacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .2);
}

/* class for every size header image */
.header-height-small {
  min-height: 280px;
}

.header-height-middle {
  min-height: 425px;

  .pbm-title {
    position: absolute;
    z-index: 201;
  }
}

.header-height-big {
  min-height: 477px;

  .pbm-title {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $colorWhite;
    text-transform: uppercase;

    h3 {
      font-weight: 400;
      font-size: 48px;
      letter-spacing: 1.3px;

      @media (max-width(768)) {
        font-size: 24px;
      }

    }
    span {
      font-size: 19px;
      font-weight: 400;
      @media (max-width(768)) {
        font-size: 12px;
      }

    }
  }
}

/* end image header */

.header-news {
  background-image: url(../images/header-bg/bg-header.jpg);
}

.header-listobject {
  background-image: url(../images/header-bg/bg-header-listobject.jpg);
}

.header-listobjectdetail {
  background-image: url(../images/header-bg/bg-header-listobjectdetail.jpg);
}

.header-photoreport {
  background-image: url(../images/new-header-bg/1.jpg);
}

.header-main {
  background-image: url(../images/header-bg/header-main.jpg);
}

.header-vacancies {
  background-image: url(../images/new-header-bg/2.jpg);
}

.header-article {
  background-image: url(../images/header-bg/bg-header-article.jpg);
}

.header-index {
  background-image: url(../images/header-bg/bg-header-index.jpg);
}

.header-wrap {
  position: fixed;
  padding: 25px 0 15px 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;

  &.default {
    background: rgba(37, 35, 42, 0.95);
    //z-index: 10000;
    z-index: 1000;
    overflow: hidden;

  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $mediaTablet) {
      justify-content: flex-start;
      width: 100%;
    }
  }

  @media screen and (max-width: $mediaTablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #222 !important;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 2;
    left: 0;
    top: 0;
    padding: 30px;
    display: none;
    z-index: 101;
    overflow: scroll;
  }

  .header-wrap-logo {
    display: flex;

    span {
      text-transform: uppercase;
      color: #fff;
      font-weight: $fontSemiBold;
    }
    a {
      .header-logo {
        width: 126px;
        height: 64px;
        fill: white;
        transition: 0.3s;

        @media screen and (max-width: $mediaTablet) {
          display: none;
        }
      }

      .header-logo-small {
        width: 87px;
        height: 44px;
        fill: black;
      }

    }
  }

  .header-wrap-menu {
    display: flex;
    align-items: center;
    transition: 0.3s;

    @media screen and (max-width: $mediaTablet) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .menu {
      display: flex;
      align-items: center;
      width: 100%;

      @media screen and (max-width: $mediaTablet) {
        flex-direction: column;
        align-items: flex-start;
        order: 3;
      }

      li {
        margin-right: 26px;

        @media screen and (max-width: $mediaNetbook) {
          margin-right: 15px;
        }

        @media screen and (max-width: $mediaTablet) {
          margin-right: 0;
          padding: 25px 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          width: 100%;
          display: block;

          &:first-child {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
          }
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          display: flex;
          align-items: center;
          color: $colorWhiteopacity;
          font-size: 14px;
          transition: 0.3s;
          border-bottom: 4px solid rgba(0, 0, 0, 0);
          padding-bottom: 7px;
          margin-top: 11px;
          letter-spacing: 1.2px;

          @media screen and (max-width: $mediaTablet) {
            padding-bottom: 0;
            margin-top: 0;
          }

          .menu-icon {
            margin-right: 25px;
            fill: rgba(255, 255, 255, 0.33);
            display: none;

            @media screen and (max-width: $mediaTablet) {
              display: block;
            }
          }

          .icon-menu-company {
            width: 20px;
            height: 18px;
            margin-left: 4.5px;
            margin-right: 29.5px;
          }

          .icon-menu-foto {
            width: 22px;
            height: 22px;
            margin-left: 3.5px;
            margin-right: 28.5px;

          }

          .icon-menu-news {
            width: 22px;
            height: 28px;
            margin-left: 3.5px;
            margin-right: 28.5px;
            fill: white;
            opacity: 0.12;
          }

          .icon-menu-object {
            width: 25px;
            height: 23px;
            margin-left: 2px;
            margin-right: 26px;
            fill: white;
            opacity: 0.12;
          }

          .icon-menu-vacancies {
            width: 29px;
            height: 19px;
          }

          &:hover {
            color: #fff;
            //border-bottom: 4px solid #F8061D;

            @media screen and (max-width: $mediaNetbook) {
              border-bottom: 4px solid rgba(0, 0, 0, 0);
            }

          }

          &.active {
            color: #fff;
            border-bottom: 4px solid #F8061D;

            @media screen and (max-width: $mediaNetbook) {
              border-bottom: 4px solid rgba(0, 0, 0, 0);
            }
          }
        }
      }
    }

    .header-wrap-lang {
      display: flex;
      align-items: center;
      margin-left: 53px;

      @media screen and (max-width: $mediaNetbook) {
        margin-left: 40px;
      }

      @media screen and (max-width: $mediaTablet) {
        margin-left: 0px;
        margin: 70px 0px 25px 0px;
      }

      .lang {
        color: $colorWhiteopacity;
        display: block;
        letter-spacing: 1.2px;
        margin-left: 10px;
        cursor: pointer;
        transition: 0.3s;

        @media screen and (max-width: $mediaTablet) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 44px;
          height: 44px;
          background-color: transparent;
          border-radius: 100%;
          margin-left: 0;

          margin-right: 5px;

          &:hover,
          &:focus {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            background-color: #000;
            border-radius: 100%;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          color: $colorWhite;
        }
      }

      .active {
        color: #ffffff;

        @media screen and (max-width: $mediaTablet) {
          background-color: rgba(0, 0, 0, 0.33);
          color: #ffffff;
        }
      }

    }
  }

  .header-wrap-menu-effect {
    margin-top: -2px;
  }
}

.header-wrap-small {
  padding: 10px 0;

  @media screen and (max-width: $mediaTablet) {
    padding: 30px;
  }
}

/*========== Mobile Menu ==========*/
.mobile-menu-wrap {
  display: none;

  @media screen and (max-width: $mediaTablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 101;
    position: fixed;
    padding: 20px 24px 20px 24px;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    &.default {
      background: #222;
      z-index: 10000;
      overflow: hidden;
    }

    .mobile-menu-wrap-pad {
      padding-top: 30px;
    }
  }

  .header-wrap-logo {

    .header-logo {
      position: relative;
      z-index: 12;
    }

    span {
      text-transform: uppercase;
      color: #fff;
      font-weight: $fontSemiBold;
    }

    a {
      .header-logo {
        width: 87px !important;
        height: 43px;
        fill: white;
      }
    }
  }
}

/*========== Гамбургер ==========*/

.hamburger-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e52445;
  position: relative;
  z-index: 10;
  width: 44px;
  height: 44px;
  border-radius: 100%;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  position: relative;
  z-index: 200;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger-box {
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 18px;
  height: 3px;
  background-color: #fff;
  z-index: 200;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -7px;
}

.hamburger-inner::after {
  bottom: -7px;
}

/*========== hamburger--squeeze ==========*/

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
