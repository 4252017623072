
// this code is refactored and copy paste from object-detail //
.object-pop-up {
  padding-top: 30px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .grid {
    margin-left: 0;
    margin-right: 0;
    background-color: white;
    padding: 0 10px;

    @media (max-width: 400px){
      padding: 0;
    }

    // change style //
    .grid-item {
      cursor: initial;
      // take of this style for white background//

      /* class for gradient START*/
      .green-gradient {
          background-image: linear-gradient(122deg, rgba(0, 41, 59, .5), rgba(38, 139, 125, .8));
          box-shadow: 0 0 17px 0 rgba(124, 134, 156, 0.1);
      }

      .red-gradient {
          background-image: linear-gradient(122deg, rgba(59, 0, 0, .5), rgba(135, 0, 0, .8));
          box-shadow: 0 0 17px 0 rgba(124, 134, 156, 0.1);
      }

      .orange-gradient {
          background-image: linear-gradient(122deg, rgba(71, 0, 0, .5), rgba(247, 107, 28, .8));
          box-shadow: 0 0 17px 0 rgba(124, 134, 156, 0.1);
      }
      /* class for gradient  END*/

      .card__object {
        background-color: rgba(0, 0, 0, 0);

        @media (max-width: 400px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        .arrow-right {
          border-color: $colorWhite;
        }

        .location-card {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);

          .location-card__wrapper {
            .location-card__road-number {
              color: $colorWhite;
              font-size: 21px;

              svg{
                margin-left: 9px;
              }



            }
            .location-card__city{
              word-break: break-all;
              letter-spacing: 1px;
            }

            .location-card__route {
              color: $colorWhite;
            }

          }


        }

        .ready-highway {
          #svg-circle-pop-up {
            position: relative;
            z-index: 1;
            width:53px;
            height:53px;
          }
          .progressbar-text{
            color:$colorWhite !important;
            position: absolute;

          }

          .ready-highway__status {
            color: $colorWhite;
            .ready-highway__title {
              color: $colorWhite;
            }
          }
        }

        .repair {
          .repair__type {
            color: $colorWhite;
            .repair__key {
              color: $colorWhite;
            }
          }
        }
      }
      // take of this style for white background // END//
    }
  }

  .share-with-mobile{
    text-align: center;
    margin: 0 10px 0 10px;
    .share-text {
      font-size: 24px;
      color: #333333;
    }

    .reference-mobile {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      overflow-x: scroll;

      // hidden scroll  for filling (this is slider)
      &::-webkit-scrollbar {
        display: none;
      }

      .reference-item{
        display: flex;
        flex-direction: column;
        //max-width: 75px;
        align-items: center;

        .reference-icon{
          display: flex;
          cursor:pointer;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin-left: 20px;

          &.fb-icon{
            background-color: #4267b2;
          }

          &.twitter-icon {
            background-color: #1ea1f2;
          }

          &.gmail-icon {
            background-color: #dc4a39;
          }

          &.copy-icon{
            background-color: #7c869c;
          }

          &.whatsapp-icon{
            background-image: linear-gradient(to top, #0bb926, #60fc7b);

            svg{
              width: 36px;
              height: 36px;
            }
          }

          &.messenger-icon{
            background-image: linear-gradient(to bottom, #00b6ff, #0087ff);
          }

          svg {
            margin: auto;
            fill: white;
          }
        }

        .reference-name{
          margin-top:10px;
          margin-left: 15px;

          //text-align: center;
          font-size: 14px;
          color: #717171;
        }
      }
    }
  }
}

/* pop-up */
.modal-object-content{
  display: flex;
  height: 100vh;
  background-color: white;
}

.modal-object {
  height: 100vh;
  width: 100%;
  background-color: #f4f4f6;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  opacity: 0;
  z-index: 10001;

  overflow: hidden;
}

/* close modal window */
.modal-object .modal-close-object {
  font-size: 20px;
  cursor: pointer;
  display: block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #717171;
}

/* background style */
.overlay-object{
  z-index:98;
  position:fixed;
  //background-color: rgba(0, 0, 0, 0.3);
  opacity:0.8;
  -moz-opacity:0.8;
  filter:alpha(opacity=80);
  width:100%;
  height:100%;
  top:0;
  left:0;
  cursor:pointer;
  display:none;
}

.notify-copy {
  display: none;
  position: absolute;
  padding: 10px 20px;
  bottom: 20px;
  left: 20px;
  font-size: 14px;
  background-color: rgba(229, 229, 229, 0.8);
  color: #7c869c;
  border-radius: 4px;
}
