/* Object List */

.main-list-object {
  max-width: 1200px;
  margin-left: 10px;
  width: 100%;
  margin: 40px auto 0 auto;

  .breadcrumbs-block {
    margin: 0 15px;
  }
}

.sort{
  margin-left: 15px;
  margin-bottom: 30px;

  .sort-block__title--mobile{
    display: none;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #333333;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      display: block;
    }
  }


  .sort-wrapper {
    max-width: 925px;
    display: flex;
    align-items: center;

    @media (max-width: 950px) {
      align-items: flex-start;
      flex-direction: column;

    }


    @media (max-width: 768px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }



    .sort-block__title {
      width: 120px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      color: #333333;
      margin-right: 10px;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .sort-block {
      display: flex;
      justify-content: space-between;
      align-items: center;


      //@media (max-width:1024px){
      //  flex-direction: column;
      //  align-items: flex-start;
      //}

      @media (max-width:950px) {
        margin-top: 15px;
      }

      @media (max-width:850px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media (max-width:768px) {
        flex-direction: row;
        align-items: center;
      }

      .sort-block__button {
        cursor: pointer;
        display: inline-block;
        padding: 5px 20px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #7c869c;
        white-space: nowrap;

        &:first-child{
          @media (max-width: 768px) {
            padding-left: 0;
          }
        }
        &.active{
          border:solid 1px rgba(2, 150, 255, 0.4);
          color:#0296ff;

          &:first-child{
            @media (max-width: 768px) {
              padding: 5px 20px;
            }
          }

        }
      }
    }
  }
}