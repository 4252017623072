
.news {
  width: 100%;
  height: auto;
}

//NEWS list



.news-item {
  display: flex;
  width: calc(100% - 20px);
  height: auto;
  min-height: 240px;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  margin-left: 10px;
  //padding-bottom: 15px;



  .news-item__text-top {
    display: flex;
    align-items: center;

    .news-item__tag {
      color: #4A90E2;
      font-weight: $fontBold;
      font-size: 12px;
    }

    .news-item__data {
      color: #AAAAAD;
      font-weight: $fontBold;
      font-size: 12px;
      //margin-left: 15px;
    }

    @media screen and (max-width: $mediaMobile) {
      justify-content: space-between;
    }
  }

  .news-item__img-link {
    display: block;
    position: relative;
    width: 100%;
    flex: 0 0 240px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    transition: 0.3s;
    background-color: #333333;

    .news-item__img-link-img {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: 0.3s;
      height: 240px;

      &:hover {
        transform: scale(1.05);
        filter: grayscale(100%);
      }
    }

    @media screen and (max-width: 1100px) {
      display: none;
    }

    @media screen and (max-width: $mediaMobile) {
      flex: 0 0 186px;
    }

  }

  .news-wrap-item-img-effect {
    filter: grayscale(100%);
    transform: scale(1.05);
  }

  .news-item__text-wrap {
    margin: 20px 30px;
    padding-bottom: 15px;

    .news-item__description{
      pre{
        white-space: pre-wrap;
      }
    }


    .news-item__title {
      color: #221F2B;
      font-size: 19px;
      line-height: 1.4;
      margin: 15px 0;
      display: inline-block;
      transition: 0.3s;
      padding-right: 15px;
      font-weight: $fontSemiBold;
      position: relative;


      @media screen and (max-width: $mediaTablet) {
        font-size: 15px;
      }

      &:hover {
        color: $colorRed;
      }

      &:after {
        content: '';
        width: 7px;
        height: 7px;
        background-color: transparent;
        border-top: solid 2px #aaaaad;
        border-right: solid 2px #aaaaad;
        transform: rotate(45deg);
        position: absolute;
        transition: 0.3s;
        top: 50%;
        margin-top: -3px;
        right: 0;
      }

      &:hover:after {
        border-top: solid 2px #E52445;
        border-right: solid 2px #E52445;
      }

    }

    .news-link-effect {
      color: red;
    }

    .news-item__description {
      color: #555;
      font-size: 14px;
      line-height: 1.4;
      font-weight: $fontSemiBold;
      margin-bottom: 20px;

      @media screen and (max-width: $mediaMobile) {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  @media screen and (max-width: $mediaMobile) {
    height: auto;
    min-height: 50px;
  }
}

//NEWS TILE

.news-wrap {
    &.news-wrap-tile{


      .news-item{
        padding-bottom: 15px;
    }
  }


  &__container{
    padding-left: 5px;
    padding-right: 5px;
  }
  .news-item__text-top {
    display: flex;
  }

  .news-item__img-link {
    width: 100% !important;
    transition: 0.3s;

    .news-link-img-tile {
      width: 100%;
    }

  }

  .news-wrap-item-img-block {
    display: block;
  }

  &.news-wrap-tile {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    .news-item {
      display: flex;
      flex-direction: column;
      flex-basis: calc(100% / 3 - 20px);
      margin: 10px;

      @media screen and (max-width: $mediaNetbook) {
        flex-basis: calc(100% / 2 - 20px);
      }

      @media screen and (max-width: 680px) {
        flex-basis: 100%;
      }
    }


    .news-wrap-item-text-top-tile {
      display: flex;
      //justify-content: space-between;
      color: #000000;
    }
  }
}

.news-description-none-js {
  display: none;

  @media screen and (max-width: $mediaNetbook) {
    display: block !important;
  }
}

.news-link-img-none-js {
  display: none !important;

  @media screen and (max-width: $mediaNetbook) {
    display: block !important;
  }
}

//END NEWS TILE


// news search
.news-search{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  .news-search__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .news-search__input {
      font-size: 16px;
      -webkit-appearance: none;

      outline: none;
      border: 1px solid rgba(151, 151, 151, 0.33);
      border-right: none;
      background-color: #F4F4F6;
      min-width: 280px;
      padding: 9px 74px 9px 10px;
      height: 41px;

      @media (max-width: 480px) {
        padding: 9px 44px 9px 10px;
        min-width: 100px;
        width:100%;
      }

      &::placeholder{
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.4px;
        color: rgba(0, 0, 0, 0.5);

      }
    }

    .news-search__button{
      cursor: pointer;
      -webkit-appearance: none;
      outline: none;
      border: none;

      width: 40px;
      height: 41px;
      background-color: #0296ff;
      line-height: 0;

      svg {
        width: 25px;
      }

    }
  }
}