.wrapper-crumbs {
  max-width: 1200px;
  margin-left: 10px;
  width: 100%;
  margin: 40px auto 0 auto;

  .breadcrumbs-block{
    margin: 0 15px 0 15px;
  }
}


.map {
  width: 100%;

  .map-wrap {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 768px){
      //margin-bottom: 200px;

    }

    // Classes for open pop-up one card
    .one-open-card {
      width: 100%;
      position: absolute;
      max-width: 1200px;
      z-index: 200;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: scale(0.9);
      transition: transform 1s;

      @media (max-width: 1300px){
        transform: scale(0.8);
      }
      @media (max-width: 1024px){
        transform: scale(0.7);
      }

      @media (max-width: 768px){
        margin-top: 80px;
        transform: scale(0.9);
      }

      .close-window-card {
        display: flex;
        position: absolute;
        width: 40px;
        height: 40px;
        background: rgba(255,255,255, 0.8);
        border-radius: 50%;
        top: -18px;
        right: -10px;
        z-index: 55;
        transition: all .3s;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover .close-map-wrap-icon:after,
        &:hover .close-map-wrap-icon:before {
          background-color: rgba(255,0,0, 1);
        }

        .close-map-wrap-icon{
          position: absolute;
          top: 19px;
          left: 10px;

          &:after{
            content: ' ';
            width: 20px;
            height: 2px;
            background-color: #333333;
            position: absolute;
            transform: rotate(45deg);
            transition: 0.3s;
          }

          &:before{
            content: ' ';
            width: 20px;
            height: 2px;
            background-color: #333333;
            position: absolute;
            transform: rotate(-45deg);
            transition: 0.3s;
          }
        }

        @media (max-width:768px) {
          right: 10px;
          top: 5px;
        }
      }
      //Style what was kill by another style

      .open-card{
        box-shadow: none;
        position: relative;


        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          margin-bottom: -50px;
          border: 30px solid transparent;
          border-top: 30px solid $colorWhite;
          left: calc(50% - 25px);
          @media (max-width: 768px){
            width: 2px;
            height: 22px;
            border: 20px solid transparent;
            border-bottom: 20px solid $colorWhite;
            margin-top: -60px;
            top: 0px;
          }
        }

        @media (max-width: 768px){
          box-shadow: -2px 10px 47px -9px rgba(0,0,0,0.54);
        }
      }
      .map-object {
        @media (max-width: 768px){
          padding-bottom: 0px;
        }
        .map-data{
          @media (max-width: 768px){
            display: none;
          }
        }
        .done-road-data {
          @media (max-width: 768px){
            margin-bottom: 10px;
          }
          .status-icon-detail {
            @media (max-width: 768px){
              display: none;
            }
          }
        }

      }
      .open-card__phone-extra {
        .phone-button{
          margin-top: 20px;

          &:nth-child(2){
            @media (max-width: 768px) {
              display: flex;

            }
          }
        }

      }
    }
    // end classes for pop-up card

    .mobile-open-road {
      background-image: url(../images/map-block.png);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      margin-right: 15px;
      top: 0;
      bottom: 0;
      margin-top: 15px;
      width: 80px;
      height: 80px;
      background-color: #7C869D;
      cursor: pointer;
      z-index: 0;//998;
      transition: 0.3 all;
      padding:22px;

      @media (max-width: 768px){
        transform: scale(0.8);
        margin: 0;
      }

      &:hover {
        svg {
          fill: $colorWhite;
        }
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 33px;
        height: 36px;
        fill: #fff;
      }
    }

    .map-block {
      background-image: url(../images/map-block.png);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: 382px;
      height: 700px;
      background-color: #000000;
      opacity: 1;
      //z-index: -1;
      transition: 0.1s;
      overflow: hidden;

      @media (max-width:768px) {
        height: 800px;
      }


      .map-block-head {
        padding: 25px 25px 25px 45px;
        background: rgba(18, 19, 24, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $mediaMobile) {
          padding-left: 35px;
          padding-right: 20px;
        }

        @media screen and (max-width: 374px) {
          padding-left: 20px;
          padding-right: 15px;
        }

        .map-block-head-left {
          display: flex;
          align-items: center;

          .close-map-wrap{
            cursor: pointer;
          }
        }

        span {
          color: #ffffff;
          font-weight: $fontSemiBold;
          text-transform: uppercase;
          letter-spacing: 0.4px;
          margin-left: 40px;
          font-size: 14px;

          @media screen and (max-width: 374px) {
            font-size: 13px;
            margin-left: 35px;
          }
        }

        svg {
          width: 26px;
          height: 28px;
          fill: #fff;
        }

        .map-block-head-close {
          cursor: pointer;
          display: block;
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }

      .map-roads {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 45px;
        padding-right: 25px;
        padding: 25px 25px 25px 45px;
        transition: 0.3s;
        cursor: pointer;
        position: relative;

        @media screen and (max-width: $mediaMobile) {
          padding-left: 30px;
          padding-right: 20px;
        }

        @media screen and (max-width: 374px) {
          padding-left: 15px;
          padding-right: 15px;
        }

        &:hover {
          background: rgba(18, 19, 24, 0.5);
        }

        .map-roads-info {
          display: flex;
          align-items: center;

          .map-roads-info-circule {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: #4c4f9d;
            border-radius: 100%;
            margin-right: 30px;
            position: relative;

            svg {
              width: 16px;
              height: 16px;
              fill:#fff;
              -webkit-background-clip: text;
              position: relative;
              z-index: 3;
            }
            img{
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              z-index: 2;
            }
          }

          .circule-orange {
            background-color: #ff8b4d;
          }

          .circule-red {
            background-color: #f8071c;
          }
          .circule-violete {
            background-color: #9870EE;
          }

          .circule-green {
            background-color: #3bdeb5;
          }

          .map-roads-info-text {

            .map-roads-info-text-title {
              font-weight: $fontSemiBold;
              font-size: 14px;
              color: #ffffff;
              display: block;
            }

            .map-roads-info-text-subtitle {
              display: block;
              color: #b3bec7;
              font-weight: $fontSemiBold;
              font-size: 12px;
              margin-top: 7px;
            }

          }
        }

        .map-roads-slide {
          width: 41px;
          height: 24px;

          input[type=checkbox] {
            height: 0;
            width: 0;
            visibility: hidden;
          }

          label {
            width: 39px;
            height: 22px;
            position: absolute;
            border-radius: 40px;
            z-index: 99;
            border: solid 1px #979797;

            &:after {
              content: '';
              position: absolute;
              top: 1px;
              left: 2px;
              width: 18px;
              height: 18px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 90px;
              transition: 0.3s;
            }
          }

          .rounded-all {
            background-color: rgba(0, 0, 0, 0.0);
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            border: solid 0px #979797;
            cursor: pointer;

            &:after {
              display: none;
            }
          }

          input:checked + label {
            background: #86869b;
            border: 1px solid #86869b;

            &:after {
              background: #fff;
            }
          }

          input:checked + label:after {
            left: 18px;
          }

        }
      }


      .nth-child-open{
        &:nth-child(1){
          right: 0;
          transition: 0.4s;
        }

        &:nth-child(2){
          right: 0;
          transition: 0.6s;
        }

        &:nth-child(3){
          right: 0;
          transition: 0.8s;
        }

        &:nth-child(4){
          right: 0;
          transition: 1s;
        }
        &:nth-child(4){
          right: 0;
          transition: 1.2s;
        }
        &:nth-child(5){
          right: 0;
          transition: 1.4s;
        }
        &:nth-child(6){
          right: 0;
          transition: 1.6s;
        }
      }

      .map-roads-first{
        right: 100%;
        transition: 0.4s;
      }

      .map-roads-second {
        right: 100%;
        transition: 0.6s;
      }

      .map-roads-third {
        right: 100%;
        transition: 0.8s;
      }

      .map-roads-fourth {
        right: 100%;
        transition: 1s;
      }
      .map-roads-five {
        right: 100%;
        transition: 1.2s;
      }
      .map-roads-nth-child {
        right: 0;
      }

    }

    .map-block-mobile {
      transition: 1s all;
      opacity: 1;

      z-index: 998;
    }

    //@media (max-width: 768px){
    //  margin-bottom: 170px;
    //}
  }



}

