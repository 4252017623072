

.fotoreport {
  width: 100%;
  height: auto;

  .container {
    @media screen and (max-width: $mediaMobile) {
      padding: 0;
    }
  }

  .breadcrumbs-block {
    @media screen and (max-width: $mediaMobile) {
      margin-left: 30px;
    }
  }
}


.fotoreport-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 0;

  @media screen and (max-width: $mediaNetbook) {
    margin: 50px -10px 0;
  }

  @media screen and (max-width: $mediaTablet) {
    margin: 0;
  }

}


.fotoreport-item {
  display: flex;
  width: 100%;
  max-width: 365px;
  background-color: #333;
  height: auto;
  min-height: 370px;
  padding: 25px 30px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 15px;
  position: relative;
  overflow: hidden;
  filter: grayscale(0%);
  background-size: cover;
  background-position: center;


  &:first-child, &:nth-child(7), &:nth-child(11) {
    width: 100%;
    max-width: 760px;
    position: relative;
  }

  &:hover .fotoreport-item__bg {
    filter: grayscale(100%);
    transform: scale(1.05);
  }

  .fotoreport-item__bg {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s;
  }

  .fotoreport-item__info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;

    .fotoreport-item__date {
      color: #ffffff;
      font-weight: $fontBold;
      font-size: 16px;
    }

    .fotoreport-item__info-road-wrap {
      overflow: hidden;
      text-overflow: ellipsis;

      .fotoreport-item__number {
        //display: flex;
        //align-items: center;
        //justify-content: center;
        background-color: #0296ff;
        height: 36px;
        //width: 73px;
        font-weight: $fontSemiBold;
        color: #ffffff;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-bottom: 18px;
        white-space: nowrap;
        padding: 5px;
        display: inline-block;

      }

      .fotoreport-item__city {
        color: #ffffff;
        font-size: 16px;
        font-weight: $fontBold;
        letter-spacing: 0.5px;
        display: inline-block;
        white-space: nowrap;


        @media screen and (max-width: $mediaMobile) {
          font-size: 14px;
        }
      }

      .fotoreport-item__miles {
        color: #ffffff;
        font-size: 14px;
        font-weight: $fontSemiBold;
        letter-spacing: 0.5px;
        display: inline-block;
        white-space: nowrap;

        @media screen and (max-width: $mediaMobile) {
          font-size: 13px;
        }
      }

    }
  }

  @media screen and (max-width: $mediaNetbook) {
    flex: 0 0 calc(100% / 2 - 20px);
    max-width: 1200px;
    margin: 10px;
    min-height: 340px;
  }

  @media screen and (max-width: $mediaTablet) {
    flex: 0 0 calc(100%);
    max-width: 1200px;
    margin: 0px;
    margin-bottom: 20px;
    min-height: 340px;
  }

  @media screen and (max-width: $mediaMobile) {
    min-height: 200px;
    padding: 10px 10px;
  }
}













