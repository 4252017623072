
.article {
  width: 100%;
  height: auto;

  .container {

    @media screen and (max-width: $mediaMiddle) {
      padding: 0;
    }
  }

  .breadcrumbs-block {

    @media screen and (max-width: $mediaMiddle) {
      padding-left: 30px;
    }
  }
}

.article-wrap {
  width: 100%;
  height: auto;
  padding: 35px 105px 25px 49px;
  background-color: $colorWhite;
  padding-right: 20%;

  pre{
    white-space: pre-wrap;
  }

  .article-img__wrapper{
    max-height: 500px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  img {
    width: 100%;

  }
  iframe{
    max-width: 100%;
  }

  @media screen and (max-width: $mediaMiddle) {
    padding: 30px;
  }

  p {
    color: #333333;
    line-height: 1.43;
    font-size: 14px;
    padding-bottom: 20px;
  }

  .article-h2 {
    color: $colorBlack;
    font-size: 36px;
    padding: 17px 0px 22px 0px;
    font-weight: 600;
    line-height: 1.4;
    width: 100%;
    max-width: 806px;

    @media screen and (max-width: $mediaNetbook) {
      font-size: 24px;
    }

    @media screen and (max-width: $mediaMobile) {
      font-size: 21px;
    }
  }

  // owl carousel inside article
  .article-carousel{
    position: relative;


    .owl-stage {


      &.owl-stage-p-0 {
        padding-left: 0 !important;
      }
    }


    .owl-item{
      position:relative;
    }

   // navigation for carousel
      .owl-nav {
        .owl-next,.owl-prev {
          position: absolute;
          height: calc(100% - 20px);
          width: 140px;
          color: transparent;
          display: flex;
          align-items: center;

          @media (max-width:768px) {
            width: 70px;
          }


          &:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border-top: 2px solid rgba(255, 255, 255, 0.8);
            border-right: 2px solid rgba(255, 255, 255, 0.8);
          }
        }

        .owl-next {
          top:0;
          right:0;
          justify-content: flex-end;

          &:hover{
            content: "";
            background-color: rgba(0, 0, 0, 0.24);
            cursor: pointer;
          }

          &.disabled{
            display: none;
          }

          &:before {
            margin-right: 20px;
              transform: rotate(45deg);
          }
          //&:after{
          //  content: '';
          //  display: block;
          //  position: absolute;
          //  width: 100%;
          //  height: 100%;
          //  background-color: rgba(0, 0, 0, 0.62);
          //}
        }

        .owl-prev {
          top:0;
          left:0;
          justify-content: start;


          &:hover{
            content: "";
            background-color: rgba(0, 0, 0, 0.24);
            cursor: pointer;
          }

          &.disabled{
            display: none;
          }

          &:before {
            margin-left: 20px;
            transform:rotate(-135deg);
          }
        }
      }

    //.owl-nav{
    //  //position: absolute;
    //  //width: 100%;
    //  //top: 0;
    //  //height: 100%;
    //  //left: 0;
    //
    //  .owl-prev,.owl-next {
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    height: 100%;
    //    width: 10%;
    //    position: absolute;
    //    color: transparent;
    //    top:0;
    //
    //    &:after{
    //      content: '';
    //      display: block;
    //      width: 20px;
    //      height: 20px;
    //      background-color: transparent;
    //      top: 0;
    //      left: 0;
    //      border-top: 2px solid rgba(255, 255, 255, 0.8);
    //      border-right: 2px solid rgba(255, 255, 255, 0.8);
    //    }
    //  }
    //
    //  .owl-next {
    //    right: 0;
    //
    //    &:after{
    //      transform: rotate(45deg);
    //    }
    //  }
    //
    //
    //  .owl-prev{
    //    left: 0;
    //
    //    &:after{
    //      transform: rotate(-135deg);
    //    }
    //  }
    //}
  }

  .article-wrap-data {
    color: #7c869c;
    font-size: 14px;
  }
}

.other-news {
  width: 100%;
  height: auto;
  padding: 20px 50px 50px 50px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-top: 2px;

  @media screen and (max-width: $mediaMiddle) {
    padding: 30px 15px 30px 30px;
  }

  .other-news-h3 {
    font-size: 20px;
    font-weight: $fontSemiBold;
    color: $colorBlack;
  }

  .other-news-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-top: 25px;

    .other-news-wrap-item {
      display: flex;
      //align-items: center;
      flex: 0 0 calc(100% / 3);
      padding-right: 25px;
      transition: 0.3s;

      @media screen and (max-width: 767px) {
        flex: 0 0 calc(100%);
        margin-bottom: 20px;
        padding-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .other-news-wrap-item-text {
        display: flex;
        flex-direction: column;
        margin-left: 13px;
        align-items: flex-start;

        .news-item-date {
          font-weight: $fontSemiBold;
          color: #999999;
          margin-bottom: 7px;
          position: relative;

          &:after {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #000000;
            position: absolute;
            margin-left: 5px;
            margin-top: 7.5px;
            border-top: 2px solid #AAAAAD;
            border-right: 2px solid #AAAAAD;
            background: transparent;
            transform: rotate(45deg);
          }
        }

        .news-item-text {
          font-size: 12px;
          color: $colorBlack;
          text-transform: uppercase;
          font-weight: $fontSemiBold;
          line-height: 17px;
          transition: 0.3s;
          letter-spacing: 0.3px;
        }
      }

      .other-news-wrap-item-img {
        height: 83px;
        width: 100%;
        max-width: 83px;
        min-width: 83px;
        position: relative;
        overflow: hidden;
        transition: 0.3s;
        background-color: #333;

        @media screen and (max-width: $mediaNetbook) {
          display: none;
        }

        @media screen and (max-width: 767px) {
          display: block;
        }

        img {
          position: absolute;
          margin: auto;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          height: 100%;

        }
      }

      &:hover .news-item-text {
        color: $colorRed;
      }

      &:hover .other-news-wrap-item-img {
        filter: grayscale(100%);
      }

    }
  }
}

.crumbs-article {
  margin: 15px 0px 40px 0px;
}