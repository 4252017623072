
// paginator //
.news-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  padding-bottom: 25px;

  @media screen and (max-width: 374px){
    justify-content: space-around;
  }

  .news-pagenavi-number:hover,
  .news-pagenavi-text:hover{
    color: $colorRed;
  }

  .news-pagenavi-number {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 16px;
    font-weight: $fontSemiBold;
    margin: 0 8px;

    @media screen and (max-width: $mediaMiddle){
      margin: 0 6px;
    }
  }

  .news-pagenavi-number-mobile{

    @media screen and (max-width: $mediaMiddle){
      display: none;
    }
  }


  .news-pagenavi-text {
    color: #000;
    font-weight: $fontSemiBold;
    transition: 0.3s;
    font-size: 12px;
  }

  .news-pagenavi-point{
    margin: 0 10px;
  }

  .news-pagenavi-text-previous {
    position: relative;

    &:after{
      content: '';
      width: 7px;
      height: 7px;
      background-color: transparent;
      border-top: solid 2px #000;
      border-right: solid 2px #000;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
      position: absolute;
      left: 0;
      margin-left: -13px;
      top: 4px;
      transition: 0.3s;
    }

    &:hover:after {
      border-top: solid 2px #E52445;
      border-right: solid 2px #E52445;
    }
  }

  .news-pagenavi-text-next {
    position: relative;

    &:before {
      content: '';
      width: 7px;
      height: 7px;
      background-color: transparent;
      border-top: solid 2px #000;
      border-right: solid 2px #000;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      margin-right: -13px;
      top: 4px;
      transition: 0.3s;
    }

    &:hover:before {
      border-top: solid 2px #E52445;
      border-right: solid 2px #E52445;
    }
  }

  .news-pagenavi-numbers {
    display: flex;
    align-items: center;
    margin: 0 25px;

    @media screen and (max-width: $mediaMiddle){
      margin: 0 15px;
    }

    @media screen and (max-width: 374px){
      display: none;
    }
  }

  .news-pagenavi-numbers .active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #201F20;
    width: 39px;
    height: 39px;
    color: $colorWhite;
    border-radius: 100%;
  }
}



.news-navigation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0 35px 0; //48px

  @media screen and (max-width: 600px){
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 27px;
    margin-top: 8px;
  }

  @media screen and (max-width: $mediaMobile){
    margin-bottom: 25px;
  }

  .crumbs{
    margin: 0;
  }
}