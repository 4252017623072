

//about company
.company {
  width: 100%;
  height: auto;
  padding: 70px 0 75px 0;
  position: relative;

  @media screen and (max-width: $mediaMobile) {
    padding: 25px 0px 40px 0px;
  }

  // change style for breadcrumbs for company page;
  .breadcrumbs-block {
    position: absolute;
    top: -180px;

    .page-title {
      color: $colorWhite;
      font-weight: 500;
    }

    .news-navigation {
      color: $colorWhite;
    }

    .crumbs {

      .crumbs-line, .crumbs-link, .crumbs-text {
        color: $colorWhite;
        font-weight: 400;
      }

    }

    @media (max-width: 480px) {
      top: -145px;
      left: 30px;
    }
  }

}


//company technologies
.company-technologies {
  width: 100%;
  height: auto;
  min-height: 547px;
  padding: 70px 0px 80px 0px;
  background-image: url(../images/company-technologies.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  transition: 1s;
  background-color: #333333;

  p {
    color: #fff;
    width: 100%;
    max-width: 674px;
  }

  @media screen and (max-width: $mediaMobile) {
    background-image: url(../images/company-technologies-mobile.png);
    padding: 30px 0px 55px 0px;
  }

}


//company-experience
.company-experience {
  width: 100%;
  height: auto;
  padding: 70px 0px 80px 0px;
  background-image: url(../images/company-exp.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .company__title{
    color: #333333;
  }

  &-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__text {
    width: 100%;
    max-width: 674px;
  }

  .company__btn {
    border: solid 1px rgba(0, 0, 0, 0.5);
    color: #333333;
  }

  @media screen and (max-width: $mediaMobile) {
    background-image: url(../images/company-exp-mobile.png);
    padding: 30px 0px 130px 0px;
    background-position: bottom;
    background-size: 100%;
    background-color: #fff;
  }

}


//company innovation
.company-innovation {
  width: 100%;
  height: auto;
  padding: 70px 0px 80px 0px;
  background-image: url(../images/company-innovation.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: #333333;

  p {
    color: #fff;
    width: 100%;
    max-width: 674px;
  }

  @media screen and (max-width: $mediaMobile) {
    background-image: url(../images/company-innovation-mobile.png);
    padding: 30px 0px 30px 0px;
  }

}




.company__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 209px;
  height: 48px;
  border: solid 1px $colorWhiteopacity;
  background-color: transparent;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    border: solid 1px $colorRed;
    color: $colorRed;
  }

  @media screen and (max-width: $mediaMobile) {
    display: none;
  }

}
.company{
  p{
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    margin-bottom: 30px;
    width: 100%;
    max-width: 1030px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: $mediaMobile) {
      padding-left: 15px;
      font-size: 14px;
    }
  }
}

.company__title {
  letter-spacing: 3px;
  font-weight: $fontBold;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 25px;

  @media screen and (max-width: $mediaMobile) {
    text-align: center;
  }
}

.company-animated {
  opacity: 0;
  transition: 1s;
}

.showcompany {
  opacity: 1;
}
























