// if some bag kill this
.owl-item {
  -ms-touch-action:pan-y !important;
  touch-action: pan-y !important;
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select:all !important;
  user-select: all !important;
}

.main-list-object {
  .object-carusel {
    .open-card {
      .open-card__left-side {
        padding: 35px 0 35px 40px !important;

        @media (max-width: $mediaMiddle) {
          //flex-basis: 100%;
          padding: 0 !important;
        }

      }
    }
  }





  .open-card {
    height: 528px;
    margin: 0 10px 25px;
    display: flex;
    justify-content: space-between;
    background-color: $colorWhite;
    -webkit-box-shadow: 16px 0px 0px -7px rgba(255, 255, 255, 0.5);
    box-shadow: 16px 0px 0px -7px rgba(255, 255, 255, 0.5);

    @media (max-width: $mediaMiddle) {
      padding: 27px 29px;
      flex-direction: column;
      margin: 25px 0;
      height: 1250px;
      //height: auto;
    }

    .open-card__left-side {
      flex-basis: 50%;

      @media (max-width: $mediaMiddle) {
        padding: 0;
        flex-basis: 40%;
      }

      .open-card__left-side-wraper {
        border-right: 1px solid rgba(151, 151, 151, .1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @media (max-width: $mediaMiddle) {
          border-right: none;
          padding-right: 0px;
          height: auto;
        }

        .open-card__header {
          display: flex;
          align-items: center;
          padding-right: 10px;

          @media (max-width: $mediaMiddle) {
            margin-bottom: 10px;
            padding-right: 0;
          }

          .open-card__icon-block {
            .open-card__icon-wrapper {
              height: 34px;
              background-color: #0296ff;
              padding-top: 3px;
              padding-left: 6px;
              padding-right: 6px;

              .open-card__icon {
                height: 28px;
                font-size: 18px;
                line-height: 1.56;
                letter-spacing: 1px;
                color: #ffffff;
                margin: 0 auto;
                text-align: center;
                white-space: nowrap;

                @media (max-width: $mediaMiddle) {
                  font-size: 14px;
                  line-height: 2;
                }
              }
            }
          }

          .open-card__road-info {
            padding: 5px;
            padding-left: 20px;
            @media (max-width: $mediaMiddle) {
              padding-left: 10px;
            }

            .open-card__location {
              text-transform: uppercase;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.56;
              color: #333333;
              display: inline-block;

              @media (max-width: 1100px) {
                font-size: 16px;
              }
              @media (max-width: 946px) {
                font-size: 13px;
              }

              @media (max-width: 480px) {
                font-size: 14px;
                letter-spacing: 0.5px;
              }
            }
          }
        }

        .open-card__body {
          margin-top: -37px;

          @media (max-width: $mediaMiddle) {
            margin-top: 0;
          }
          .open-card__body-row {
            display: flex;
            justify-content: flex-start;

            @media (max-width: $mediaMiddle) {
              flex-direction: column;
              align-items: flex-start;
              margin-top: 9px;
            }

            &.phone-hide{
              @media (max-width: $mediaMiddle) {
                display: none;
              }

            }

            .open-card__body-col {
              flex-basis: 40%;
              font-size: 14px;
              line-height: 1.8;
              text-align: left;
              color: #000000;
              font-weight: 600;

              @media (max-width: 1024px) {
                font-size: 12px;
              }

              @media (max-width: $mediaMiddle) {
                margin-bottom: 0;
                color: #9b9b9b;
              }

              &:nth-child(even) {
                flex-basis: 63%;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.5;
                color: #7c869c;
                margin-bottom: 20px;



                @media (max-width: 1024px) {
                  font-size: 12px;
                }

                @media (max-width: $mediaMiddle) {
                  margin-bottom: 10px;
                  color: #333333;
                  font-size: 15px;
                  letter-spacing: 0.2px;
                  font-weight: 600;
                }
              }

              &.currency {
                font-size: 18px;
                @media (max-width: $mediaMiddle) {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .open-card__footer {
          display: flex;
          //margin-top: 74px;//26px;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;

          @media (max-width: $mediaMiddle) {
            display: none;
          }

          .open-card__references {
            flex-basis: 50%;

            .open-card__share {
              width: 150px;
              height: 14px;
              font-size: 12px;
              text-align: left;
              color: #7c869c;
              margin-bottom: 18px;
            }

            .open-card__reference-icons {
              width: 222px;
              a {
                display: inline-block;
                margin-right: 14px;

                i {
                  display: flex;
                  border-radius: 50%;
                  background-color: red;
                  width: 33px;
                  height: 33px;

                  .reference-svg {
                    margin: auto;
                    fill: $colorWhite;
                  }

                  &.google-i {
                    background-color: #dc4a39;
                    .google-reference {
                      width: 19px;
                      height: 12px;
                    }
                  }

                  &.facebook-i {
                    background-color: #4267b2;
                    .facebook-reference {
                      width: 8px;
                      height: 16px;
                    }
                  }

                  &.twitter-i {
                    background-color: #1ea1f2;
                    .twitter-reference {
                      width: 15px;
                      height: 12px;
                    }
                  }

                  &.linkedin-i {
                    background-color: #c4c4c4;
                    .linkedin-reference {
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }
            }
          }

          .report-button-block {
            cursor: pointer;
            flex-basis: 52%;
            align-self: flex-end;
            display: flex;
            align-items: center;
            justify-content: center;
            //width: 207px;
            height: 40px;
            border: solid 1px rgba(2, 150, 255, 0.4);
            margin-right: 20%;
            min-width: 120px;

            &:hover {
              border-color: #f8061d;
              .report-button {
                color: #f8061d;
              }
            }
            .report-button {
              letter-spacing: 2px;
              text-transform: uppercase;
              color: #0296ff;
            }
          }
        }
      }
    }
    .open-card__right-side-outline {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      justify-content: space-between;


      padding-left: 10px;
      padding-bottom: 42px;
      padding-right: 5px;
      @media (max-width: $mediaMiddle) {
        flex-basis: 40%;
        padding: 0;
      }
      // LEGEND
      .right-side__legend{
        margin-left: 22px;
        //margin-bottom: 42px;
        //margin-top: 12px;
        @media (max-width: $mediaMiddle) {
          background-color: rgba(244, 244, 246, 0.5);
          margin: 10px -35px;
          padding: 8px 31px;
        }


        h5{
          width: 119px;
          height: 28px;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.33;
          letter-spacing: normal;
          color: #333333;
          margin-bottom: 10px;
        }

        .legend-group{
          display: flex;
          //justify-content: space-around;
          align-items: center;
          justify-content: flex-start;



          @media (max-width: 1100px) {
            flex-wrap: wrap;
          }

          .legend-text {
            &.legend-text-1{
              width: 56px;
            }
            &.legend-text-2{
              width: 99px;
            }
            &.legend-text-3{
              width:99px;
            }
            &.legend-text-4{
              width:77px;
            }

            height: 36px;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: normal;
            color: #7c869c;
          }
        }

        .lenend-group-wrapper {
          display: flex;
          align-items: center;
          margin-right: 4%;


          &:nth-child(1){
            @media (max-width: $mediaMiddle) {
              order: 2;
            }
          }
          &:nth-child(2){
            @media (max-width: $mediaMiddle) {
              order: 1;
            }
          }
          &:nth-child(3){
            @media (max-width: $mediaMiddle) {
              order: 3;
            }
          }
          &:nth-child(4){
            @media (max-width: $mediaMiddle) {
              order:4;
            }
          }


          @media (max-width: 1100px) {
            flex-basis: calc(100% /2);
            margin-bottom: 10px;
            margin-right: 0;
          }

          @media (max-width: $mediaMiddle) {
            margin-bottom: 24px;
          }
        }

        .legend-group-img {
          margin-right: 9px;
          position: relative;
          width: 16px;
          height: 31px;

          &.orange{

            .vertical-line{
              background-color: #f4a522;
            }
            .legend-small-circule{
              background-color: #f8e71c;
            }
            .legend-big-circule{
              background-color: #fdbd53;
            }
          }
          &.light-green{
            .vertical-line{
              background-color: #4edfbe;
            }
            .legend-small-circule{
              background-color: #4edfbe;
            }
            .legend-big-circule{
              background-color: #8affe5;
            }
          }
          &.dark-green{
            .vertical-line{
              background-color: #00acac;
            }
            .legend-small-circule{
                background-color: #00acac;
            }
            .legend-big-circule{
              background-color: #50e3c2;
            }
          }
          &.gray{
            .vertical-line{
                background-color: #ececec;
            }
            .legend-small-circule{
              background-color: #d1d1d1
            }
            .legend-big-circule{
              background-color: #f4f4f4;
            }
          }


          .vertical-line{
            z-index: 44;
            position: absolute;
            top:0;
            left: calc(50% - 1px);
            height: 31px;
            width: 2px;

          }

          .legend-small-circule{
            z-index: 46;
            position: absolute;
            width: 8px;
            height: 8px;

            border-radius: 50%;
            left: calc(50% - 4px);
            top: calc(50% - 4px);
          }
          .legend-big-circule{
            z-index: 45;
            position: absolute;
            width: 16px;
            height: 16px;
            transform: rotate(-90deg);

            box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.18);
            border-radius: 50%;
            left: calc(50% - 8px);
            top: calc(50% - 8px);
          }
        }

      }
    }

    .open-card__right-side {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $mediaMiddle) {
        flex-direction: column;
        //flex-basis: 100%;
        padding-bottom: 50px;
        flex-basis: 42%;
      }

      // text svg
      text {
        transform: translate(10px,4px) !important;
      }

      .open-card__map {
        flex: 0 0 75%;
        margin-left: -50px;
        @media (max-width: $mediaMiddle) {
          order: 2;
        }

        svg{
          transition: 1s all;
          //width: 100%;
          width: 100%;
          height: 100%;
          overflow: visible;
        }
      }

      .open-card__controller {
        position: relative;
        flex: 0 0 25%;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding-top: 43px;
        align-items: center;

        @media (max-width: $mediaMiddle) {
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          order: 1;
          //margin-bottom: 40px;
          //margin-right: -20px;
          //margin-left: -20px;
          padding-top: 0;

        }

        .open-card__progress-block {
          width: 66px;
          height: 66px;
          margin-bottom: 11px;
          margin-right: 10px;

          .js-open-card__progress {
            position: relative;
          }

          @media (max-width: $mediaMiddle) {
            position: absolute;
            top: 90px;
            left: 80%;
          }
        }

        .length-of-road {
          font-size: 24px;
          font-weight: 600;
          color: #7c869c;
          width: 80px;

          .length {
            white-space: nowrap;
          }


          @media (max-width: $mediaMiddle) {
            //margin: 10px;
            //margin-left: 20px
            width: auto;
          }
          .phone-hide{
            @media (min-width: $mediaMiddle) {
              display: none;
            }
          }
          sub{
            font-size: 14px;
            font-weight: 400;
            bottom: 0;
          }

          &.add-line {
            &:after {
              content: "";
              display: block;
              width: 80px;
              height: 1px;
              margin-top: 14px;
              margin-bottom: 10px;
              background-color: rgba(151, 151, 151, 0.1);

              @media (max-width: $mediaMiddle) {
                display: none;
              }
            }
          }
        }

        .length-description {
          width: 107px;
          height: 17px;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #7c869c;
          
          @media (max-width: $mediaMiddle) {
            font-size: 12px;
            font-weight: 600;
            color: #7c869c;
            line-height: 1.5;
          }

          &.description-done {
            width: 82px;
            height: 36px;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #00acac;

            @media (max-width: $mediaMiddle) {
              font-size: 12px;
              font-weight: 600;
              color: #7c869c;
              width: 149px;
              line-height: 1.5;
            }
          }

        }
      }
    }

    .open-card__phone-extra {
      margin: 0 auto;
      width:100%;
      flex-basis: 10%;
      @media (min-width: $mediaMiddle) {
        display: none;
      }

      .phone-button {
        display: flex;
        cursor: pointer;
        height: 50px;
        border: solid 1px rgba(2, 150, 255, 0.4);
        transition: .3s all;
        //margin-top: 68px;

        &:hover {
          border-color: #f8061d;

          .report-button {
            color: #f8061d;
          }
        }

        .report-button {
          margin: auto;
          text-transform: uppercase;
          color: #0296ff;
        }

        &:nth-child(2){
          @media (max-width: 768px) {
            display: none;
            //margin-top: 20px;
          }
        }
      }

      .open-card__phone-share {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 54px 0px;
        margin-top: 48px;
        text-align: center;

        @media(max-width: $mediaMiddle) {
          margin-top: 25px;
          margin-bottom: 70px;
        }

        i {
          svg {
            fill:#7c869c;
          }
        }

        .share-text {
          margin-right: 10px;
          color:#7c869c;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }
  }

}
