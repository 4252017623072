// Photo popup

/*=========== POP-UP WINDOW ===============*/
.background-blur {
  overflow: hidden;

 .header, .main-list-object, footer, .photo-carousel, .fotoreport {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
}


.modal-foto {
  width: 100%;
  height: 100vh;
  background-color: rgba(30, 30, 30, 0.9);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  opacity: 0;
  z-index: 11000;
  overflow: hidden;


  // TODO
  .modal-foto-carousel{
    height: 400px;
    overflow: hidden;
  }

}


/* CLOSE WINDOW*/
.modal-foto .modal-close {
  font-size: 20px;
  position: absolute;
  top: 50px;
  right: 40px;
  cursor: pointer;
  display: block;
  text-align: center;
  z-index: 111001;


  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }

  .modal-close-svg {
    width: 26px;
    height: 26px;
    fill: rgba(255, 255, 255, 0.33);

    @media (max-width: 768px) {
      top: 16px;
      right: 16px;
    }
  }
}




/* КОНТЕНТ */
.modal-foto-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  //padding: 30px 0 20px 0;
  position: relative;
  padding-top: 52px;


  @media (max-width: 768px) {
    margin-top: 5px;
    padding-top: 0;
  }
  @media (max-width: 420px) {
    margin-top: 30px;
    justify-content: flex-start;
  }

  .modal-top {
    text-align: center;
    color: #a8a8a8;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 18px;

    @media (max-width: 768px) {
      margin-bottom: 5px;
      text-align: center;
    }
    @media (max-width:420px) {
      margin-bottom: 18px;
    }
  }

  .modal-top-date,.modal-top-text {
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.9;

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }





// Navigation buttons //

  .navigation-carousel {
    display: block;

    //justify-content: space-around;
    height: 40px;
    position: relative;
    margin-bottom: 20px;
    margin-top: -46px;

    @media (max-width: 768px) {
      display: none;
    }


    #prev-item,  #next-item {
      position: relative;
      margin: 0;
      cursor: pointer;

      span{
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        transition: 0.5s all;
      }

      &.disabled{
        display: none;
      }
    }

    #next-item {
      position: absolute;
     right: 20%;
      font-weight: 600;
      &:hover {
        span {
          color: #ffffff;
        }
        .arrow-right-item {
          animation: move 1.5s infinite;
          animation-timing-function: linear;
          animation-delay: .1s;
          border-top: 3px solid #ffffff;
          border-right: 3px solid #ffffff;

          &:before {
            opacity: 1;
            border-top: 3px solid #ffffff;
            border-right: 3px solid #ffffff;
            animation: move 1.5s infinite;
            animation-timing-function: linear;
            animation-delay: .2s;
          }

          &:after {
            opacity: 1;
            border-top: 3px solid #ffffff;
            border-right: 3px solid #ffffff;
            animation: move 1.5s infinite;
            animation-timing-function: linear;
            animation-delay: .3s;
          }
        }
      }
    }

    #prev-item {
      position: absolute;
      left: 20%;
      font-weight: 600;
      &:hover {
        span {
          color: #ffffff;
        }
        .arrow-left-item {
          animation: move 1.5s infinite;
          animation-timing-function: linear;
          animation-delay: .1s;
          border-top: 3px solid #ffffff;
          border-right: 3px solid #ffffff;

          &:before {
            opacity: 1;
            border-top: 3px solid #ffffff;
            border-right: 3px solid #ffffff;
            animation: move 1.5s infinite;
            animation-timing-function: linear;
            animation-delay: .2s;
          }

          &:after {
            opacity: 1;
            border-top: 3px solid #ffffff;
            border-right: 3px solid #ffffff;
            animation: move 1.5s infinite;
            animation-timing-function: linear;
            animation-delay: .3s;
          }
        }
      }
    }

    .arrow-left-item {
      flex: 0 0 50%;
      position: absolute;
      top: -11px;
      left: -60px;
      width: 16px;
      height: 16px;
      border-top: 3px solid #999;
      border-right: 3px solid #999;
      background-color: rgba(0, 0, 0, .0) !important;
      transform: rotate(-135deg) scale(.8);
      cursor: pointer;
      display: inline-block;
      transition: 1s;

      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -10px;
        top: -10px;
        opacity: 0;
        transition: 1s;
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -17px;
        top: -17px;
        opacity: 0;
        transition: 1.5s;
      }

      &:hover:before {
        opacity: 1;
        border-top: 3px solid #ffffff;
        border-right: 3px solid #ffffff;
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .2s;
      }

      @keyframes move {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }

        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }

    }

    .arrow-right-item {
      flex: 0 0 50%;
      position: absolute;
      top: -10px;
      right: -60px;
      width: 16px;
      height: 16px;
      border-top: 3px solid #999;
      border-right: 3px solid #999;
      background-color: rgba(0, 0, 0, .0) !important;
      transform: rotate(45deg) scale(.8);
      cursor: pointer;
      display: inline-block;
      transition: 1s;

      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -10px;
        top: -10px;
        opacity: 0;
        transition: 1s;
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -17px;
        top: -17px;
        opacity: 0;
        transition: 1.5s;
      }

      @keyframes move {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }

        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }

    }
  }
  // end navigation buttons //

  // Classes for img //
  .ul-img-item {
    position: relative;
    cursor: grab;
    img {
      //position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .back-mask {
      position: absolute;
      padding: 31px 29px;
      bottom:0;
       //bottom:239px;
      width: 100%;
      height: 234px;
      background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0), #212121);
      display: flex;
      align-items: flex-end;

      @media (max-width:768px) {
        height: 100px;
        padding: 10px 10px;
      }
    }
    .description{
      color:white;
      font-size: 16px;
      font-weight: 600;
      max-width: 600px;
      text-align: left;

      @media (max-width:768px) {
        font-size: 13px;
      }
    }
  }
  // end //

  // CARUSEL  STALE //
  .hsarea {
    display: inline-block;
    padding: 5px;
    margin: 5px 0 5px 0;
    width: 100%;

    @media (max-width: 768px) {
      margin-top: 30px;
    }
    @media (max-width: 325px) {
      margin-top: 5px;
    }

    @media (max-height: 412px) {
      margin-top: 0px;
    }
  }

  .hsframe {
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
  }

  .hsframe ul {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 100%;
    max-height: 540px;
    overflow: hidden;



  }

  .hsframe ul li {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    max-width: 1070px;
    height: auto;
    margin: 0 10px 0 10px;
    padding: 0;
    cursor: pointer;
    text-align: center;

    max-height: 540px;
    overflow: hidden;

    // To do after know size of photo
    @media (max-width: 1440px) {
      max-width: 900px;
    }

    @media (max-width: 1170px) {
      width: 800px;
      max-height: 530px;

    }

    @media (max-width: 992px) {
      max-width: 700px;
      max-height: 470px;
    }

    @media (max-width: 768px) {
      max-width: 600px;
      max-height: 402px;
    }
    @media (max-width: 640px) {
      max-width: 450px;
      max-height: 298px;
    }

    @media (max-width: 480px) {
      max-width: 300px;
      max-height: 199px;
    }
  }

  /* Scrollbar */

  .hsscrollbar .hshandle .hsmousearea {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 20px;
  }

  .hsscrollbar {
    margin-top: 33px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .hsmousearea {
    width: 100% !important;
    height: 15px !important;
    background-color: white;

  }

  .hsscrollbar .hshandle {
    width: 100%;
    height: 100%;
    //background: #292a33;
    cursor: pointer;
  }

  .hsscrollbar {
    width: 100%;
    height: 11px;
  background-color: rgba(255, 255, 255, 0.5);
    line-height: 0;
  }

  /*-- content style --*/
  .srch-photo {
    height: 194px;
  }

  .srch-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 2px;
    text-align: center;
  }

  // end //

}




// more-photo
.more-album {
  background:#f4f4f6;
  height:606px !important;
  width: 402px !important;
  padding: 19px 30px !important;


  @media (max-width: 1440px) {
      height: 511px !important;
  }

  @media (max-width: 1170px) {
    height: 455px !important;
  }

  @media (max-width: 992px) {
      height: 398px !important;
      width: 302px !important;
      padding: 10px 15px !important;
  }

  @media (max-width: 370px) {
    width: 325px !important;
    max-width: 325px !important;
    height: 340px !important;
  }


  // for iphone
  @media (max-width: 325px) {
    max-width: 291px !important;
    height: 340px !important;
  }




  h2{
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 16px;

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
  .small-album__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

   .small-album__item {
     flex: 0 0 calc(100%/2);
     position: relative;
     display: block;
     width: 159px;
     height: 103px;
     margin-bottom: 20px;
      cursor: pointer;
     overflow: hidden;

     @media (max-width: 992px) {
       width: 115px;
       height: 77px;
     }

     img{
       top:0;
       left: 0;
       position: absolute;
       width: 159px;
       height: 103px;

       @media (max-width: 992px) {
         width: 115px;
         height: 77px;
       }
     }

     span{
      position: absolute;
       display: block;
       bottom:10px;
       left: 10px;
       color: #ffffff;
       font-size: 14px;
       font-weight: 600;

       @media (max-width: 992px) {
         font-size: 12px;
       }
     }
   }
  }
}




/* КОНТЕНТ */
//.modal-foto-content {
//  height: 100vh;
//  padding: 0 0 20px 0;
//  position: relative;
//
//  @media (max-width: 768px) {
//    margin-top: 30px;
//    justify-content: flex-start;
//  }
//
//  @media (max-height: 412px) {
//    margin-top: 13px !important;
//  }
//
//  .modal-top {
//    font-weight: $fontSemiBold;
//    text-align: center;
//    letter-spacing: 3.6px;
//    font-size: 36px;
//    margin-bottom: 18px;
//    text-transform: uppercase;
//
//    @media (max-height: 412px) {
//      font-size: 25px !important;
//      margin-bottom: 0 !important;
//    }
//  }
//
//  .modal-top-text {
//    display: block;
//    text-align: center;
//    color: #333333;
//    font-size: 14px;
//    opacity: 0.9;
//    font-weight: $fontSemiBold;
//
//    @media (max-width: 768px) {
//      font-size: 13px;
//    }
//
//    @media (max-height:412px) {
//      display: none !important;
//    }
//  }
//}










