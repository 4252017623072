
.yeogurt-info {
  .map-title {
    font-size: 30px;
    text-align: center;
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: $fontSemiBold;
    margin-top: 40px;
  }

  .map-text {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: $fontSemiBold;
    color: #999999;
    margin: 15px 0px 45px 0px;
  }
}




.news-carousel {
  width: 100%;
  height: auto;
  background: url(../images/bg-carousel-news.png);
  padding: 30px 0 90px 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #333333;
  max-height: 400px;
  overflow: hidden;

  @media screen and (max-width: $mediaMobile) {
    padding: 30px 0 66px 0;
  }

  .container {
    @media screen and (max-width: 375px) {
      padding: 0 0 0 30px;
    }

    @media screen and (max-width: 320px) {
      padding: 0 0 0 15px;
    }
  }

  .news-carousel-h2 {
    text-align: center;
    font-weight: $fontSemiBold;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 35px;

    @media screen and (max-width: $mediaMobile) {
      font-size: 24px;
    }
  }

  .news-carousel-wrap {
    opacity: 0;

    .news-carousel-item {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 20px;
      transition: 0.3s;
      height: 207px;
      margin-right: 10px;

      @media screen and (max-width: $mediaNetbook) {
        background-color: rgba(255, 255, 255, 1);
      }

      @media screen and (max-width: 375px) {
        width: 280px;
        box-shadow: 14px 0px 0px -7px rgba(255, 255, 255, 0.5);
      }

      @media screen and (max-width: 320px) {
        width: 260px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 14px 0px 0px -7px rgba(255, 255, 255, 0.5);
      }
    }

    .news-wrap-item-text {
      width: 100%;
      max-height: 167px;
      overflow: hidden;

      .news-wrap-item-text-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 11px;

        .news-tag {
          font-size: 12px;
          font-weight: $fontBold;
          color: #4a90e2;
          opacity: 0.9;
        }

        .news-data {
          color: #aaaaad;
          font-size: 12px;
          font-weight: $fontBold;
        }
      }

      .news-link {
        display: block;
        font-size: 16px;
        font-weight: $fontSemiBold;
        color: #221f2b;
        transition: 0.3s;
        margin-bottom: 10px;
        overflow: hidden;
        position: relative;
        line-height: 1.4;
        padding-right: 15px;

        &:hover {
          color: $colorRed;
        }

        &:after {
          content: '';
          width: 7px;
          height: 7px;
          background-color: transparent;
          border-top: solid 2px #aaaaad;
          border-right: solid 2px #aaaaad;
          transform: rotate(45deg);
          position: absolute;
          transition: 0.3s;
          top: 50%;
          margin-top: -6px;
          right: -5px;
          margin-right: 9px;
          z-index: 1000000;
        }

        &:hover:after {
          border-top: solid 2px #E52445;
          border-right: solid 2px #E52445;
        }
      }

      .news-description {
        p {

        overflow: hidden;
        color: #373a3c;
        line-height: 1.43;
        font-size: 14px;
      }

          *{
            white-space: pre-wrap;
          }
      }
    }

    .owl-prev-news {
      position: absolute;
      width: 16px;
      height: 16px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      border-left: 0 solid red;
      border-bottom: 0 solid red;
      background-color: transparent;
      transform: rotate(-135deg);
      left: 0;
      top: 50%;
      margin-top: -11px;
      margin-left: -30px;
      cursor: pointer;
      display: block;
      transition: 1s;
      outline: none;
      user-select: element !important;

      &.disabled {
        display: none;
      }

      @media screen and (max-width: 1300px) {
        display: none;
      }

      &:hover {
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .1s;
        border-top: 3px solid #f80c2f;
        border-right: 3px solid #f80c2f;
      }

      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -10px;
        top: -10px;
        opacity: 0;
        transition: 1s;
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -17px;
        top: -17px;
        opacity: 0;
        transition: 1.5s;
      }

      &:hover:before {
        opacity: 1;
        border-top: 3px solid #f80c2f;
        border-right: 3px solid #f80c2f;
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .2s;
      }

      &:hover:after {
        opacity: 1;
        border-top: 3px solid #f80c2f;
        border-right: 3px solid #f80c2f;
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .3s;
      }

      @keyframes move {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }

        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }

    .owl-next-news {
      position: absolute;
      width: 16px;
      height: 16px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      border-left: 0px solid red;
      border-bottom: 0px solid red;
      background-color: rgba(0, 0, 0, .0);
      transform: rotate(45deg);
      right: 0;
      top: 50%;
      margin-top: -11px;
      margin-right: -30px;
      cursor: pointer;
      display: block;
      transition: 1s;

      &.disabled {
        display: none;
      }

      @media screen and (max-width: 1300px) {
        display: none;
      }

      &:hover {
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .1s;
        border-top: 3px solid #f80c2f;
        border-right: 3px solid #f80c2f;
      }

      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -10px;
        top: -10px;
        opacity: 0;
        transition: 1s;
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        background-color: rgba(0, 0, 0, .0);
        cursor: pointer;
        right: -17px;
        top: -17px;
        opacity: 0;
        transition: 1.5s;
      }

      &:hover:before {
        opacity: 1;
        border-top: 3px solid #f80c2f;
        border-right: 3px solid #f80c2f;
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .2s;
      }

      &:hover:after {
        opacity: 1;
        border-top: 3px solid #f80c2f;
        border-right: 3px solid #f80c2f;
        animation: move 1.5s infinite;
        animation-timing-function: linear;
        animation-delay: .3s;
      }

      @keyframes move {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }

        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }

    }

    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 100%;
      width: 100%;
      margin-top: 40px;

      @media screen and (max-width: $mediaMobile) {
        margin-top: 30px;
      }

      .owl-dot {

        &.active span {
          background-color: #f80c2f;
        }

        span {
          display: flex;
          align-items: center;
          width: 22px;
          height: 2px;
          margin: 5px 7px;
          background: rgba(255, 255, 255, 0.33);
          display: block;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            background-color: #f80c2f;
          }
        }
      }
    }
  }

  .news-carousel-wrap.owl-loaded{
    opacity: 1 !important;
  }
}



