/* object-list detail */



.main-list-object {




  .our-object-detail {
    width: 100%;

    //Change classes for caruser
    .object-carusel {
      .owl-nav {
        opacity: 1;
        position: relative;

        @media (max-width: 1291px) {
          display: none !important;
        }



        @media (max-width: $mediaMiddle) {
          width: 100%;
          opacity: 1;
          position: absolute;
          bottom: 0;
          display: flex !important;
          justify-content: space-between;
          height: 60px;
        }

        .owl-prev-page {
          position: absolute;
          top: -320px;
          left: -30px;
          background: transparent;
          width: 25px;
          height: 25px;
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 7px;
          opacity: 0.5;
          transform: rotate(135deg);
          z-index: 3;
          transition: all .3s;

          &:hover {
            border-color: #f8061d;
            opacity: 1;
          }

          @media (max-width: $mediaMiddle) {
            display: block;
            position: absolute;

            //display: block;
            top: 0px;
            left: 0px;
            //background-color: red;
            transform: rotate(0deg);
            border: none;
            border-radius: 0;
            width: 50%;
            height: 60px;
            padding: 0;
            margin: 0;
            opacity: 1;
            background-color: #7c869c;
            transition: 1s all;
            &.active-button{
                background-color: #b4bac6;
            }

            &:before {

              @media (max-width: $mediaMiddle) {
                content: "Попередній об’єкт";
                display: block;
                //padding-top: 19px;
                font-size: 14px;
              }

              @media (max-width: 400px) {
                content: "Наступний об’єкт";
                font-size: 12px;
              }
            }

            &:after {
              @media (max-width: $mediaMiddle) {
                content: "";
                display: block;
                position: absolute;
                top: 24px;
                left: calc(10% - 5px);
                background: transparent;
                width: 5px;
                height: 5px;
                border: solid $colorWhite;
                border-width: 0 1px 1px 0;
                padding: 2px;
                opacity: 1;
                transform: rotate(135deg);
              }
            }

            &:hover {
              background-color: #b4bac6;;
            }
          }
        }

        .owl-next-page {
          position: absolute;
          top: -320px;
          right: -30px;
          background: transparent;
          width: 25px;
          height: 25px;
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 7px;
          transform: rotate(-45deg);
          z-index: 4;
          opacity: 0.5;
          transition: all .3s;

          &:before {
            @media (max-width: $mediaMiddle) {
              content: "Наступний об’єкт";
              font-size: 14px;
              display: block;
              //padding-top: 19px;
            }

            @media (max-width: 400px) {
              content: "Наступний об’єкт";
              font-size: 12px;
            }
          }

          &:after {
            @media (max-width: $mediaMiddle) {
              content: "";
              display: block;
              position: absolute;
              top: 24px;
              right: 10%;
              background: transparent;
              width: 5px;
              height: 5px;
              border: solid $colorWhite;
              border-width: 0 1px 1px 0;
              padding: 2px;
              opacity: 1;
              transform: rotate(-45deg);
            }
          }

          &:hover {
            border-color: #f8061d;
            opacity: 1;
          }

          @media (max-width: $mediaMiddle) {
            position: absolute;
            padding: 0;
            margin: 0;
            width: 50%;
            display: block;
            top: 0px;
            height: 60px;
            right: 0px;
            transform: rotate(0deg);

            background-color: red;
            border: none;
            opacity: 1;
            background-color: #7c869c;
            border-radius: 0;

            transition: 1s all;
            &.active-button{
              background-color: #b4bac6;
            }

            &:hover {
              background-color: #b4bac6;;
            }
          }
        }
      }

      .owl-dots {
        .owl-dot {
          width: 30px;
          height: 2px;
          background-color: #afafaf;
          margin: 4px;

          &:hover {
            background-color: #f8061d;
            transition: all 0.3s;
          }

          &.active {
            background-color: #f8061d;
          }

          span {
            display: none;
          }
        }
      }
    }
    // Change classes end

    // HERE WAS CARD STYLE I TAKE OF IT TO COMPONENT

    .object-cards {
      @media (max-width: $mediaMiddle) {
        display: none;
      }
    }
  }

}