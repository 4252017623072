
body {
  .custom-iw .gm-style-iw, .custom-iw .gm-style-iw>div, .custom-iw .gm-style-iw>div>div{
    @media (max-width: 768px) {
      height: 650px !important;
      width: 350px !important;
    }
  }

  .custom-iw, .custom-iw>div:first-child>div:last-child {
    @media (max-width: 768px) {
      width: 350px !important;
      height: 665px !important;
    }
  }

  .custom-iw {
    // Костиль!
    transform: translate(-231px, 0);
    @media (max-width: 1200px) {
      transform-origin: center  bottom;
      transform: translate(-231px, 0) scale(0.9);
    }
    @media (max-width: 1100px) {
      transform-origin: center  bottom;
      transform: translate(-231px, 0) scale(0.8);
    }
    @media (max-width: 970px) {
      transform-origin: center  bottom;
      transform: translate(-231px, 0) scale(0.7);
    }

    @media (max-width: 768px) {
      transform: translate(27px, 0);
    }
    @media (max-width: 325px) {
      transform: translate(27px, 0) scale(0.8);
    }
    // hide old arrow
    > div {
      &:first-child {

        div {

          &:nth-child(3) {
            display: none !important;
          }
          &:nth-child(1) {
            display: none !important;
          }
        }
      }
    }
  }
}


.hide-for-desktop {
  @media (min-width: $mediaMiddle) {
    display: none;
  }
}

.gm-style-iw {
  //background: black;
  overflow: visible !important;
  div {
    overflow: visible !important;
  }
// show new arrow
  &:after {
    content: '';
    width: 0;
    height: 0;
    border: 22px solid transparent;
    border-top-color:white;
    z-index: 4;
     position: absolute;
    left: calc(50% - 22px);
    top: auto;
    bottom: -40px;
    margin: auto;
  }
}


.map{
  margin-top: 40px;

  .map-wrap{
    height: 100%;

    #gmap-object{
      height: 700px;

      @media (max-width: 768px) {
        height: 800px;
      }
    }

    .open-card {
      margin: 0 20px 25px;
      //min-height: 200px;
      height: 510px;
      display: flex;
      justify-content: space-between;
      background-color: $colorWhite;
      -webkit-box-shadow: 14px 0px 0px -7px rgba(255, 255, 255, 0.5);
      box-shadow: 14px 0px 0px -7px rgba(255, 255, 255, 0.5);

      @media (max-width: $mediaMiddle) {
        padding: 0 20px;
        flex-direction: column;
        max-height: 1200px;
        margin: 0;
        //height: auto;
        height: 648px;
      }

      .open-card__left-side {
        flex-basis: 50%;
        padding: 20px 0 35px 20px !important;

        //@media (max-width: $mediaMiddle) {
        //  flex-basis: 100%;
        //  padding: 0 !important;
        //}
        @media (max-width: $mediaMiddle) {
          padding: 0 !important;
          flex-basis: 40%;
        }


        .open-card__left-side-wraper {
          border-right: 1px solid rgba(151, 151, 151, .1);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          //padding-right: 30px;

          @media (max-width: $mediaMiddle) {
            border-right: none;
            padding-right: 0px;
          }

          .open-card__header {
            display: flex;
            align-items: center;

            @media (max-width: $mediaMiddle) {
              padding-bottom: 20px;
              border-bottom: 1px solid rgba(151, 151, 151, .1);
            }

            .open-card__icon-block {

              .open-card__icon-wrapper {
                height: 34px;
                background-color: #0296ff;
                padding-top: 3px;
                padding-right: 6px;
                padding-left: 6px;

                .open-card__icon {
                  height: 28px;
                  font-size: 18px;
                  line-height: 1.56;
                  letter-spacing: 1px;
                  color: #ffffff;
                  margin: 0 auto;
                  text-align: center;
                  font-weight: 600;
                  white-space: nowrap;

                  @media (max-width: $mediaMiddle) {
                    font-size: 14px;
                    line-height: 2;
                  }
                }
              }
            }

            .open-card__road-info {
              padding: 5px;
              padding-left: 20px;
              padding-right: 10px;

              @media (max-width: $mediaMiddle) {
                padding-left: 10px;
              }

              .open-card__location {
                text-transform: uppercase;
                font-size: 18px;
                letter-spacing: 2px;
                font-weight: 600;
                line-height: 1.56;
                color: #333333;

                @media (max-width: $mediaMiddle) {
                  font-size: 13px;
                  letter-spacing: 0.5px;
                  line-height: 1.6;

                }
              }
            }
          }

          .open-card__body {
            margin-top: -37px;
            @media (max-width: $mediaMiddle) {
              margin-top: 10px;
            }

            .open-card__body-row {
              display: flex;
              justify-content: flex-start;
              //align-items: center;

              @media (max-width: $mediaMiddle) {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 5px;
              }

              &.phone-hide{
                @media (max-width: $mediaMiddle) {
                  display: none;
                }

              }

              .open-card__body-col {
                flex-basis: 38%;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.95;
                text-align: left;
                color: #000000;
                margin-bottom: 15px;
                letter-spacing: 0.5px;

                @media (max-width: $mediaMiddle) {
                  margin-bottom: 0;
                  color: #9b9b9b;
                  line-height: 1.5;

                }

                &:nth-child(even) {
                  flex-basis: 62%;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 1.5;
                  color: #7c869c;
                  @media (max-width: $mediaMiddle) {
                    margin-bottom: 5px;
                    color: #333333;
                    font-size: 16px;
                    line-height: 1.2;
                  }
                }

                &.currency {
                  font-size: 18px;
                  @media (max-width: $mediaMiddle) {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .open-card__footer {
            display: flex;
            //margin-top: 74px;//26px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            @media (max-width: $mediaMiddle) {
              display: none;
            }

            .open-card__references {
              flex-basis: 50%;

              .open-card__share {
                width: 156px;
                height: 14px;
                letter-spacing: 1px;
                font-size: 12px;
                text-align: left;
                color: #7c869c;
                margin-bottom: 18px;
              }

              .open-card__reference-icons {
                width: 222px;
                a {
                  display: inline-block;
                  margin-right: 14px;

                  i {
                    display: flex;
                    border-radius: 50%;
                    background-color: red;
                    width: 33px;
                    height: 33px;

                    .reference-svg {
                      margin: auto;
                      fill: $colorWhite;
                    }

                    &.google-i {
                      background-color: #dc4a39;
                      .google-reference {
                        width: 19px;
                        height: 12px;
                      }
                    }

                    &.facebook-i {
                      background-color: #4267b2;
                      .facebook-reference {
                        width: 8px;
                        height: 16px;
                      }
                    }

                    &.twitter-i {
                      background-color: #1ea1f2;
                      .twitter-reference {
                        width: 15px;
                        height: 12px;
                      }
                    }

                    &.linkedin-i {
                      background-color: #c4c4c4;
                      .linkedin-reference {
                        width: 15px;
                        height: 15px;
                      }
                    }
                  }
                }
              }
            }

            .report-button-block {
              cursor: pointer;
              flex-basis: 52%;
              align-self: flex-end;
              display: flex;
              align-items: center;
              justify-content: center;
              //width: 207px;
              height: 40px;
              border: solid 1px rgba(2, 150, 255, 0.4);
              margin-right: 20%;
              min-width: 120px;

              &:hover {
                border-color: #f8061d;
                .report-button {
                  color: #f8061d;
                }
              }
              .report-button {
                letter-spacing: 2px;
                text-transform: uppercase;
                color: #0296ff;
              }
            }
          }
        }
      }


      .open-card__right-side-outline {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        justify-content: space-between;


        padding-left: 37px;
        padding-bottom: 42px;
        padding-right: 5px;

        @media (max-width: $mediaMiddle) {
          flex-basis: 40%;
          padding: 0;
        }
        // LEGEND
        .right-side__legend{
          //margin-bottom: 42px;
          //margin-top: 12px;
          @media (max-width: $mediaMiddle) {
          display: none;
            //background-color: rgba(244, 244, 246, 0.5);
            //margin: 10px -35px;
            //padding: 8px 31px;
          }


          h5{
            width: 144px;
            height: 28px;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.33;
            letter-spacing: normal;
            color: #333333;
            margin-bottom: 10px;
          }

          .legend-group{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .legend-text {
              &.legend-text-1{
                width: 56px;
              }
              &.legend-text-2{
                width: 99px;
              }
              &.legend-text-3{
                width:99px;
              }
              &.legend-text-4{
                width:77px;
              }

              height: 36px;
              font-size: 12px;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: normal;
              color: #7c869c;
            }
          }

          .lenend-group-wrapper {
            display: flex;
            align-items: center;
            margin-right: 4%;


            &:nth-child(1){
              @media (max-width: $mediaMiddle) {
                order: 2;
              }
            }
            &:nth-child(2){
              @media (max-width: $mediaMiddle) {
                order: 1;
              }
            }
            &:nth-child(3){
              @media (max-width: $mediaMiddle) {
                order: 3;
              }
            }
            &:nth-child(4){
              @media (max-width: $mediaMiddle) {
                order:4;
              }
            }


            @media (max-width: $mediaMiddle) {
              margin-bottom: 24px;
            }
          }

          .legend-group-img {
            margin-right: 9px;
            position: relative;
            width: 16px;
            height: 31px;

            &.orange{

              .vertical-line{
                background-color: #f4a522;
              }
              .legend-small-circule{
                background-color: #f8e71c;
              }
              .legend-big-circule{
                background-color: #fdbd53;
              }
            }
            &.light-green{
              .vertical-line{
                background-color: #4edfbe;
              }
              .legend-small-circule{
                background-color: #4edfbe;
              }
              .legend-big-circule{
                background-color: #8affe5;
              }
            }
            &.dark-green{
              .vertical-line{
                background-color: #00acac;
              }
              .legend-small-circule{
                background-color: #00acac;
              }
              .legend-big-circule{
                background-color: #50e3c2;
              }
            }
            &.gray{
              .vertical-line{
                background-color: #ececec;
              }
              .legend-small-circule{
                background-color: #d1d1d1
              }
              .legend-big-circule{
                background-color: #f4f4f4;
              }
            }


            .vertical-line{
              z-index: 44;
              position: absolute;
              top:0;
              left: calc(50% - 1px);
              height: 31px;
              width: 2px;

            }

            .legend-small-circule{
              z-index: 46;
              position: absolute;
              width: 8px;
              height: 8px;

              border-radius: 50%;
              left: calc(50% - 4px);
              top: calc(50% - 4px);
            }
            .legend-big-circule{
              z-index: 45;
              position: absolute;
              width: 16px;
              height: 16px;
              transform: rotate(-90deg);

              box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.18);
              border-radius: 50%;
              left: calc(50% - 8px);
              top: calc(50% - 8px);
            }
          }

        }
      }



      .open-card__right-side {
        //flex-basis: 39%;
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;


        @media (max-width: $mediaMiddle) {
          flex-direction: column;
          flex-basis: 100%;
          //padding-bottom: 50px;
        }

        // text svg
        text {
          transform: translate(10px,4px) !important;
        }

        .open-card__map {
          flex: 0 0 78%;


          @media (max-width: $mediaMiddle) {
            //order: 2;
            display: none;
          }

          svg{
            transition: 1s all;
            //width: 100%;
            width: 100%;
            //height: 100%;
            overflow: visible;
          }
        }

        .open-card__controller {
          margin-top: -12px;
          position: relative;
          flex: 0 0 22%;
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          padding-top: 33px;
          align-items: center;

          @media (max-width: $mediaMiddle) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            order: 1;
            padding-top: 0;
            flex-basis: 100%;
            padding-right: 60px;
            align-items: center;

          }

          .open-card__progress-block {
            width: 66px;
            height: 66px;
            margin-bottom: 11px;
            margin-left: -18px;

            .js-open-card__progress {
              position: relative;
            }

            @media (max-width: $mediaMiddle) {
              display: none;
            }
          }

          .length-of-road {
            font-size: 24px;
            font-weight: 500;
            color: #7c869c;
            width: 80px;

            .length {
              white-space: nowrap;
            }

            sub{
              font-size: 14px;
              font-weight: 400;
              bottom: 0;
            }

            @media (max-width: $mediaMiddle) {
              //margin: 10px;
              //margin-left: 20px;
              height: 50px;

            }

            &.add-line {
              &:after {
                content: "";
                display: block;
                width: 80px;
                height: 1px;
                margin-top: 20px;
                margin-bottom: 20px;
                background-color: rgba(151, 151, 151, 0.1);

                @media (max-width: $mediaMiddle) {
                  display: none;
                }
              }
            }
          }

          .length-description {
            width: 107px;
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7c869c;

            @media (max-width: $mediaMiddle) {
              font-size: 12px;
              font-weight: 600;
              color: #7c869c;
              line-height: 1.5;
            }

            &.description-done {
              width: 82px;
              height: 36px;
              font-size: 12px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #00acac;

              @media (max-width: $mediaMiddle) {
                font-size: 12px;
                font-weight: 600;
                color: #7c869c;
                width: 149px;
                line-height: 1.5;
              }
            }

            &.description-plan {
              color: #f5a623;
            }
          }
        }
      }

      .open-card__phone-extra {
        //margin: 0 auto;
        //width:100%;
        margin: 0 auto;
        width:100%;
        flex-basis: 10%;

        @media (min-width: $mediaMiddle) {
          display: none;
        }

        .phone-button {
          display: flex;
          cursor: pointer;
          height: 45px;
          border: solid 1px rgba(2, 150, 255, 0.4);
          //transition: .3s all;
          //margin-top: 68px;

          &:hover {
            border-color: #f8061d;

            .report-button {
              color: #f8061d;
            }
          }

          .report-button {
            margin: auto;
            text-transform: uppercase;
            color: #1b98fc;
          }

          &:nth-child(2){
            @media (max-width: 768px) {
              //display: none;
              //margin-top: 20px;
              margin-top: 15px;
            }
          }
        }

        .open-card__phone-share {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 20px 0px;
          text-align: center;

          i {
            svg {
              fill:#7c869c;
            }
          }

          .share-text {
            margin-right: 10px;
            color:#7c869c;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

//.object-carusel {
//  .open-card {
//    .open-card__left-side {
//      padding: 27px 46px !important;
//
//      @media (max-width: $mediaMiddle) {
//        flex-basis: 100%;
//        padding: 0 !important;
//      }
//
//    }
//  }
//}
