/*******************
Preloader
********************/
.preloader{
    width: 100%;
    height: 100%;
    top:0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
  background-repeat: no-repeat;
  width:200px;
  height: 100px;
}