
.footer {
  width: 100%;
  height: auto;
  background: url(../images/bg-footer.jpg);
  padding: 40px 0 54px 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: #333333;

  @media screen and (max-width: $mediaMobile) {
    background: none;
    background-color: #222;
  }

}

.footer-wrap {
  display: flex;

  @media screen and (max-width: $mediaNetbook) {
    justify-content: space-around;
  }

  @media screen and (max-width: $mediaMobile) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 100px;
  }

}

.footer-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 52px;

  .footer__logo-icon {
    height: 50px;
    width: 100px;

    @media screen and (max-width: $mediaMobile) {
      position: absolute;
      top: 58px;
      left: 50%;
      margin-left: -50px;
    }
  }

  .footer__copyright {
    color: $colorWhiteopacity;
    line-height: 18px;
    margin: 23px 0px;
    font-size: 12px;

    @media screen and (max-width: $mediaMobile) {
      font-size: 14px;
    }
  }

  .footer-social-wrap {
    display: flex;
    align-items: center;

    .footer-social-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 39px;
      height: 39px;
      background-color: rgba(216, 216, 216, 0.1);
      border-radius: 100%;
      margin-right: 20px;
      transition: 0.3s;

      &__icon {
        fill: rgba(255, 255, 255, 0.3);
      }

      &__icon-fb {
        height: 20px;
        width: 9px;
      }

      &__icon-youtube {
        height: 20px;
        width: 16px;
      }

      &__icon-instagram {
        height: 20px;
        width: 20px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover .footer-social-item__icon {
        transition: 0.3s;
        fill: #fff;
      }

      @media screen and (max-width: $mediaMobile) {
        margin-right: 30px;
      }

    }
  }

  @media screen and (max-width: $mediaNetbook) {
    margin-left: 0;
  }

  @media screen and (max-width: $mediaMobile) {
    align-items: center;
    order: 2;
  }

}

.footer-menu-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 265px;

  .footer-menu{
    li a {
      color: $colorWhiteopacity;
      line-height: 26px;
      transition: 0.3s;
      font-size: 12px;

      &:hover {
        color: $colorRed;
      }
    }
  }

  @media screen and (max-width: $mediaNetbook) {
    margin-left: 0;
  }

  @media screen and (max-width: $mediaTablet) {
    display: none;
  }

}

.footer-contacts-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 300px;

  .footer-contacts-item {
    font-size: 12px;
    color: $colorWhiteopacity;
    line-height: 21px;

    @media screen and (max-width: $mediaMobile) {
      font-size: 21px;
      line-height: 36px;
      letter-spacing: 1px;
    }
  }

  .footer-contacts-item__address {
    margin-bottom: 20px;

    @media screen and (max-width: $mediaMobile) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media screen and (max-width: $mediaNetbook) {
    margin-left: 0;
  }

}

.footer__title {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  font-weight: $fontSemiBold;
  margin-bottom: 19px;
  display: block;
}








