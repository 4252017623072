
.error-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;

  .logo {

  }
  //
  .img-404 {
    width: 338px;
    height: 337px;
    margin-bottom: -40px;
    //

    @media (max-width: 768px) {
      width: 238px;
      height: 237px;
      margin-bottom: 0px;
    }
    @media (max-width: 340px) {
      margin-bottom: 15px;
    }
  }

  .description-error {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h1 {
      width: 560px;
      height: 48px;

      font-size: 41px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #333333;
      margin-bottom: 24px;
      text-align: center;
      font-family: 'Open Sans', sans-serif;

      @media (max-width: 768px) {
        margin-bottom: -5px;
        font-size: 20px;
        letter-spacing: 1px;
        width: 100%;
      }
    }

    h5 {
      width: 560px;
      height: 19px;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #848485;
      margin-bottom: 65px;
      text-align: center;
      font-family: 'Open Sans', sans-serif;

      @media (max-width: 768px) {
        width: 300px;
        margin-bottom: 50px;
        font-size: 14px;
        letter-spacing: 0.2px;
      }
    }

    .back {
      font-family: 'Open Sans', sans-serif;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 15px;
      color: white;
      padding: 9px 32px;
      border-radius: 22px;
      background-color: #f8041d;

      &:hover{
          background-color:#CC0015;
      }
    }
  }

}