
// object card //

.grid {
  background-color: #f4f4f6;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;


  .grid-item {
    position: relative;
    width: 376px;
    //height: 237px;
    height: 227px;
    //min-width: 310px;
    overflow: hidden;
    cursor: pointer;
    flex: 0 0 calc(100% / 3 - 20px);

    //margin: 0 auto;
    margin-right: 30px;
    &:nth-child(3n){
      margin-right: 0px;
    }


    margin-bottom: 20px;
    padding: 25px;

    @media (max-width: 1024px) {
      flex: 0 0 calc(100% / 2 - 15px);
      margin-right: 0px;

      &:nth-child(odd) {
        margin-right: 30px;
      }
    }
    @media (max-width: 670px) {
      flex: 0 0 100%;
    }

    .card__img {
      width: 100%;
      height: 237px;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    /* class for gradient START*/
    .green-gradient {
      &:hover {
        background-image: linear-gradient(122deg, rgba(0, 41, 59, .5), rgba(38, 139, 125, 0.8));
        box-shadow: 0 0 17px 0 rgba(124, 134, 156, 0.1);
      }
    }

    .red-gradient {
      &:hover {
        background-image: linear-gradient(122deg, rgba(59, 0, 0, .5), rgba(135, 0, 0, 0.8));
        box-shadow: 0 0 17px 0 rgba(124, 134, 156, 0.1);
      }
    }

    .orange-gradient {
      &:hover {
        background-image: linear-gradient(122deg, rgba(71, 0, 0, .5), rgba(247, 107, 28, 0.8));
        box-shadow: 0 0 17px 0 rgba(124, 134, 156, 0.1);
      }
    }
    /* class for gradient  END*/

    .card__object {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(255, 255, 255, 1);
      padding: 25px;
      padding-top: 10px;
      padding-bottom: 35px;
      transition: .3s;

      &:hover {
        background-color: rgba(0, 0, 0, 0);

        .card__arrow {
          border-color: $colorWhite;
          transition: 1s all;
          animation: mymove 1s infinite;

          @keyframes mymove {
            0%   {transform: rotate(-45deg) scale(1)}
            50%  {transform: rotate(-45deg) scale(1.2)}
            100% {transform: rotate(-45deg) scale(1)}
          }
        }

        .location-card {
          border-bottom: 1px solid rgba(255,255,255,.2);

          .location-card__wrapper {
            .location-card__road-number{
              color: $colorWhite;

              .st31{
                stroke: $colorWhite;
              }
            }

            .location-card__route {
              color: $colorWhite;
            }
          }
        }

        .ready-highway {
          .ready-highway__icon {
            .progressbar-text{
              color:$colorWhite !important;
            }
          }

          .ready-highway__status {
            color: $colorWhite;
            .ready-highway__title {
              color: $colorWhite;
            }
          }
        }

        .repair {
          .repair__type {
            color: $colorWhite;
            .repair__key {
              color: $colorWhite;
            }
          }
        }
      }

      /* arrow link */
      .card__arrow{
        position: absolute;
        top: 51%;
        right: 10%;
        border: solid #7c869c;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 7px;
        transform: rotate(-45deg);
        z-index: 3;
      }

      /* info about road */
      .location-card {
        border-bottom: 1px solid rgba(212,215,222, .33);

        .location-card__wrapper {
          height: 30px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 10px;
          align-items: center;

          .location-card__road-number {
            margin-right: 25px;
            font-size: 22px;
            text-align: left;
            color: #7c869c;
            white-space: nowrap;

            justify-items: center;
            display: flex;

            @media (max-width:768px) {
              font-size: 20px;
            }
          }

          .location-card__route {
            line-height: 20px;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            color: #333333;

            // for 3 dots
            overflow: hidden;


            @media (max-width: 1200px) {
              font-size: 12px;
            }

            .location-card__city{
              // for 3 dots
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;


              letter-spacing: 1.5px;
              text-transform: uppercase;
            }
          }
        }
      }

      /* road done */
      .ready-highway {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;

        .ready-highway__icon {
          flex: 0 0 20%;
          position: relative;
          margin-right: 10px;
          width: 40px;
          height: 50px;

          .js-card__progress {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width:53px;
            height:53px;
          }
        }

        .ready-highway__status {
          flex: 0 0 80%;
          margin-bottom: 10px;
          width: 172px;
          height: 39px;
          font-size: 14px;
          line-height: 1.29;
          text-align: left;
          color: #333333;

          .ready-highway__title {
            font-size: 12px;
            line-height: 2.1;
            color: #7c869c;
            text-transform: uppercase;
          }
        }
      }

      /* type of repair */
      .repair {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;

        .repair__icon {
          flex: 0 0 20%;
          padding-left: 8px;
          margin-right: 10px;
          width: 40px;
        }

        .repair__type {
          flex: 0 0 80%;
          height: 50px;
          width: 172px;
          height: 50px;
          font-size: 14px;
          line-height: 1.29;
          text-align: left;
          color: #333333;

          .repair__key {
            font-size: 12px;
            line-height: 2.1;
            color: #7c869c;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
